import en from './en';

import { AssetDoors, AssetEuroNorm, AssetFuel, AssetGearboxType, AssetPartPosition, AssetSeatUpholstery, AssetStateOfUse, AssetTachograph, AssetType, AssetUseType, AssetWheelchairRamp, AuctionState, BackendErrorCode, BusClass, CruiseControlType, DeckType, GasTanksMaterials, Glasses, RequireScrappingFilter, UserType, VehicleCondition } from '~/apiClient';

type i18nKeys = keyof typeof en;
type i18nValues = (typeof en)[i18nKeys];

const es: Record<i18nKeys, i18nValues> = {
    account: {
        bids: {
            title: 'Sus ofertas',
            emptyState: {
                base: 'Aún no has pujado.',
                cta: 'Participar en subastas',
            },
            filters: {
                bidPosition: {
                    base: 'Adjudicación',
                    anyOption: 'Todas las pujas',
                    maxBidder: 'Su oferta es la mejor',
                    outbid: 'Oferta superada',
                },
                state: {
                    base: 'Estado de la subasta',
                    anyOption: 'Todas las subastas',
                    [AuctionState.Expired]: 'Subastas completadas',
                    [AuctionState.Running]: 'Subastas en curso',
                },
            },
        },
        sales: {
            title: 'Sus ventas',
            emptyStateText: 'No hay subastas con un vendedor asociado a esta cuenta.',
        },
    },
    common: {
        logout: 'Salir de',
        login: 'Entrar',
        loginSignup: 'Entrar/Registrarse',
        loginTitle: 'Iniciar sesión en Fleequid',
        profile: 'Tu perfil',
        searchPlaceholder: 'Buscar...',
        searchNoResults: 'No se han encontrado resultados para"{query}"',
        email: 'Email',
        yourAccount: 'Tu cuenta',
        language: 'Idioma',
        showMore: 'Ver más',
        showLess: 'Ver menos',
    },
    cookie: {
        name: 'Nombre',
        provider: 'Proveedor',
        purpose: 'Propósito',
        expiry: 'Fecha de caducidad',
        type: 'Tipo',
    },
    error: {
        backend: {
            [BackendErrorCode.InvalidEnumValue]: 'Tipo de datos no válidos',
            [BackendErrorCode.InvalidDate]: 'Formato de fecha no válido',
            [BackendErrorCode.InvalidLength]: 'Longitud incorrecta. Asegúrese de haber introducido sólo los caracteres necesarios',
            [BackendErrorCode.NotAString]: 'Tipo no válido; introduzca texto',
            [BackendErrorCode.EmptyString]: 'Faltan datos. Complete toda la información requerida',
            [BackendErrorCode.PrismaError]: 'No se han podido guardar los datos; pruebe con otro valor',
            [BackendErrorCode.AuthAuthenticationVersionMismatch]: 'Su sesión ha expirado. Vuelva a iniciar sesión para continuar',
            [BackendErrorCode.AuthWrongCredentials]: 'Credenciales no válidas',
            [BackendErrorCode.AuthNoUserByEmail]: 'No hay ninguna cuenta asociada a la dirección de correo electrónico introducida.',
            [BackendErrorCode.AuthWrongPassword]: 'La contraseña introducida es incorrecta.',
            [BackendErrorCode.UserIsBanned]: 'Su cuenta ha sido suspendida. Póngase en contacto con nosotros para obtener más información.',
            [BackendErrorCode.AuthEmailNotVerified]: 'La dirección de correo electrónico introducida aún no ha sido verificada. Hemos enviado un nuevo enlace para verificar el correo electrónico.',
            [BackendErrorCode.SignupUserAlreadyExists]: 'La dirección de correo electrónico ya está registrada, si ha olvidado su contraseña intente recuperarla.',
            [BackendErrorCode.UploadFileError]: 'Error al cargar el archivo',
            [BackendErrorCode.IsNotTrue]: 'Falta selección. Marcar opción obligatoria',
            [BackendErrorCode.EntityNotFound]: 'No se han encontrado resultados',
            [BackendErrorCode.PasswordResetEmailNotExists]: 'No hay ninguna cuenta asociada a este correo electrónico',
            [BackendErrorCode.EmailChangeWrongPassword]: 'No puede cambiar la dirección de correo electrónico porque la contraseña es incorrecta',
            [BackendErrorCode.CantBidUserCompanyEqSellerCompany]: 'Este activo es propiedad de su empresa, no puede comprarlo.',
            [BackendErrorCode.AuctionUpdateDenied]: 'Solo los usuarios de empresas vendedoras están autorizados para esta acción.',
            authentication: 'Error de autenticación; por favor, intente acceder de nuevo',
        },
        '404': {
            title: '404',
            subtitle: 'Página no encontrada',
            description: 'Lo sentimos, la página solicitada no existe. Sin embargo, encontrarás mucho más que explorar en Fleequid.',
            button: 'Volver a la página de inicio',
        },
        '500': {
            title: '500',
            subtitle: 'Se ha producido un error',
            description: 'Disculpe las molestias. Ya estamos trabajando para resolver el problema.',
            help: {
                base: 'Mientras tanto, por favor inténtelo de nuevo más tarde o {contactUs}',
                contactUs: 'póngase en contacto con nosotros en {email}, o {phone} para emergencias',
            },
            button: 'Volver a la página de inicio',
        },
        default: {
            title: 'Error',
            subtitle: 'Se ha producido un error inesperado',
            description: 'Ya estamos trabajando para resolver el problema.',
            button: 'Volver a la página de inicio',
        },
    },
    a11y: {
        auctionDetail: 'Más información sobre {name}',
    },
    adp: {
        imageGallery: 'Galería completa ({totalImages})',
        videoGallery: 'Ver vídeos ({totalVideos})',
        imagePagination: 'Fotos {0} de {1}',
        videoPagination: 'Vídeo {0} de {1}',
        papers: 'Documentos',
        registrationDocument: 'Certificado de registro',
        cocDocument: 'Certificado de Conformidad (COC)',
        cocDocumentAbsent: 'El certificado de conformidad no está disponible.',
        maintenanceCard: 'Historial de mantenimiento',
        detailsTitle: 'Datos del vehículo | Datos de los vehículos',
    },
    asset: {
        type: {
            [AssetType.Bus]: 'Autobús',
        },
        class: {
            base: 'Clase',
            [BusClass.ClassOne]: 'Urbano',
            [BusClass.ClassTwo]: 'Interurbano',
            [BusClass.ClassThree]: 'Turístico',
            [BusClass.ClassA]: 'Clase A',
            [BusClass.ClassB]: 'Clase B',
            [BusClass.SchoolBus]: 'Autobús escolar',
        },
        kilometers: 'Kilometraje',
        firstRegistration: 'Matriculación',
        antiPollution: 'Normativa anticontaminación',
        euroNorm: {
            [AssetEuroNorm.Euro0]: 'Euro 0',
            [AssetEuroNorm.Euro1]: 'Euro 1',
            [AssetEuroNorm.Euro2]: 'Euro 2',
            [AssetEuroNorm.Euro3]: 'Euro 3',
            [AssetEuroNorm.Euro4]: 'Euro 4',
            [AssetEuroNorm.Euro5]: 'Euro 5',
            [AssetEuroNorm.Euro6]: 'Euro 6',
        },
        toBeScrapped: 'A desguazar',
        expiredInspection: 'Revisión caducada',
        bundle: 'Paquete',
        vehicleSpecs: 'Especificación del vehículo',
        bundleSpecs: 'Especificaciones del lote',
        vehiclesInBundle: 'Vehículos incluidos en el lote',
        vehicleIndex: 'Vehiculo #{index}',
        vehicleCount: '{count} vehículos',
        description: 'Descripción',
        version: 'Versión',
        geographicalLocation: 'Ubicación',
        vehicleCondition: {
            base: 'Estado del vehículo',
            [VehicleCondition.Blocked]: 'No funciona',
            [VehicleCondition.Rollable]: 'Remolcable',
            [VehicleCondition.Running]: 'Marchando',
        },
        registrationDocumentCountry: 'Nacionalidad del documento',
        conditionsAndMaintenance: 'Condiciones y Mantenimiento',
        stateOfUse: {
            present: 'Presente',
            missing: 'Faltante',
            [AssetStateOfUse.Insufficient]: 'Insuficiente',
            [AssetStateOfUse.WorkingAndMaintained]: 'Funcionando y mantenido regularmente',
            [AssetStateOfUse.LikeNew]: 'Como nuevo',
        },
        stateOfUseGearbox: 'Estado de la caja de cambios',
        stateOfUseAirConditioner: 'Estado del aire acondicionado',
        stateOfUseBreakingSystem: 'Estado del sistema de frenos',
        stateOfUseDrivingOrgans: 'Estado de los órganos de conducción',
        stateOfUseEngine: 'Estado del motor',
        stateOfUsePowerSystem: 'Estado del sistema de alimentación',
        stateOfUseTimingBelt: 'Estado de la correa de distribución',
        stateOfUseTransmissionDevices: 'Estado de la transmisión',
        stateOfUseTurbine: 'Estado de la turbina',
        useType: {
            base: 'Nutzungsart',
            [AssetUseType.OwnUse]: 'Eigennutzung',
            [AssetUseType.ThirdPartRent]: 'Drittvermietung',
            [AssetUseType.Line]: 'Linie',
        },
        enginePosition: 'Motorposition',
        position: {
            [AssetPartPosition.Rear]: 'Posterior',
            [AssetPartPosition.Central]: 'Central',
            [AssetPartPosition.Frontal]: 'Frontal',
        },
        missingParts: 'Partes faltantes',
        length: 'Longitud',
        height: 'Altura',
        width: 'Anchura',
        vin: 'Marco',
        converter: 'Equipamiento',
        visitDate: {
            base: 'Visible',
            fromTo: 'el {date} del {fromTime} al {toTime}',
        },
        inspectionExpiryDate: 'Fecha límite de revisión',
        doors: {
            base: 'Puertas',
            [AssetDoors.Double]: '2',
            [AssetDoors.DoubleDouble]: '2+2',
            [AssetDoors.DoubleDoubleDouble]: '2+2+2',
            [AssetDoors.DoubleDoubleSingle]: '2+2+1',
            [AssetDoors.DoubleSingleSingle]: '2+1+1',
            [AssetDoors.Single]: '1',
            [AssetDoors.SingleDouble]: '1+2',
            [AssetDoors.SingleSingle]: '1+1',
            [AssetDoors.SingleDoubleSingle]: '1+2+1',
            [AssetDoors.SingleSingleSingle]: '1+1+1',
            [AssetDoors.SingleDoubleDouble]: '1+2+2',
        },
        seatUpholstery: {
            base: 'Cubierta del asiento',
            [AssetSeatUpholstery.Fabric]: 'Tejido',
            [AssetSeatUpholstery.Leather]: 'Cuero',
            [AssetSeatUpholstery.Plastic]: 'Plástico',
            [AssetSeatUpholstery.FabricLeather]: 'Cuero/tejido',
        },
        totalCapacity: 'Número total de asientos',
        seats: 'Asientos',
        seatsFromS1: 'Asientos incl. permiso de circulación (S1)',
        standingPlaces: 'Plazas de pie',
        standingPlacesFromS2: 'Plazas de pie según el permiso de circulación (S2)',
        foldingSeats: 'Asientos plegables',
        wheelchairPlaces: 'Plazas PMR',
        companionSeats: 'Plazas para acompañantes',
        wheelchairRamp: {
            base: 'Tipo Rampa PRM',
            [AssetWheelchairRamp.Absent]: 'Ausente',
            [AssetWheelchairRamp.Electric]: 'Eléctrica',
            [AssetWheelchairRamp.Manual]: 'Manual',
            [AssetWheelchairRamp.Electropneumatic]: 'Electroneumático',
        },
        luggageCapacity: 'Capacidad del maletero',
        passThroughLuggageCompartment: 'Maletero pasante',
        hatBox: 'Caja para sombreros',
        transmission: 'Caja de cambios',
        gearboxType: {
            base: 'Tipo de caja de cambios',
            [AssetGearboxType.Auto]: 'Automático',
            [AssetGearboxType.Manual]: 'Manual',
            [AssetGearboxType.SemiAuto]: 'Semiautomático',
        },
        gearboxBrand: 'Marca de la caja de cambios',
        gearboxModel: 'Modelo de caja de cambios',
        glasses: {
            base: 'Gafas',
            [Glasses.Single]: 'Individuos',
            [Glasses.Double]: 'Doble',
        },
        openableWindows: 'Ventanas de apertura',
        engine: 'Motor',
        engineBrand: 'Marca del motor',
        engineModel: 'Modelo de motor',
        hp: 'Potencia',
        kw: 'Potencia',
        displacement: 'Cilindrada',
        cylinders: 'Número de cilindros',
        fuel: {
            base: 'Alimentación',
            [AssetFuel.CNG]: 'GNC',
            [AssetFuel.Electric]: 'Electricidad',
            [AssetFuel.GPL]: 'GLP',
            [AssetFuel.Diesel]: 'Gasóleo',
            [AssetFuel.Gasoline]: 'Gasolina',
            [AssetFuel.Hybrid]: 'Híbrido',
            [AssetFuel.Hydrogen]: 'Hidrógeno',
        },
        fuelTankCapacity: 'Capacidad del depósito',
        gasTanks: 'Depósitos de gas',
        gasTanksMaterial: {
            base: 'Material del depósito',
            [GasTanksMaterials.Aluminum]: 'Aluminio',
            [GasTanksMaterials.Composite]: 'Compuesto',
            [GasTanksMaterials.Steel]: 'Acero',
        },
        batteryPower: 'Capacidad de la batería',
        chargingType: 'Tipo de carga',
        axlesTires: 'Ejes y neumáticos',
        wheelSize: 'Dimensión de los neumáticos',
        wheels: 'Número de neumáticos (dobles o simples)',
        axles: 'Ejes',
        wheelsSizeAxle1: 'Dimensión de la rueda eje 1',
        wheelsSizeAxle2: 'Dimensión de la rueda Eje 2',
        wheelsSizeAxle3: 'Dimensión rueda eje 3',
        alloyWheels: 'Llantas de aleación',
        spareWheel: 'Rueda de repuesto',
        firePreventionSystem: 'Sistema contra incendios',
        firePreventionSystemBrand: 'Marca del sistema contra incendios',
        abs: 'ABS',
        asr: 'ASR',
        retarder: 'Retardador',
        breathalyzer: 'Respirador',
        tachograph: { base: 'Tacógrafo', [AssetTachograph.Absent]: 'Ausente', [AssetTachograph.Analog]: 'Analógico', [AssetTachograph.Digital]: 'Digital' },
        indicatorSigns: 'Paneles indicadores',
        indicatorSignsBrand: 'Fabricante del panel indicador',
        accessories: 'Accesorios',
        cruiseControl: {
            base: 'Control de crucero',
            [CruiseControlType.No]: 'No',
            [CruiseControlType.Adaptive]: 'Adaptativo',
            [CruiseControlType.Standard]: 'Estándar',
        },
        deckType: {
            base: 'Tipo de suelo',
            [DeckType.LowEntry]: 'Entrada baja',
            [DeckType.LowFloor]: 'Piso Bajo',
            [DeckType.NormalFloor]: 'Piso Normal',
        },
        acDriver: 'Aire acondicionado del conductor',
        driverBerth: 'Cama del conductor',
        wifi: 'Wi-Fi',
        cctv: 'Videovigilancia (CCTV)',
        internalCameras: 'Cámaras internas',
        externalCameras: 'Cámaras externas',
        retrocamera: 'Cámara de visión trasera',
        microphone: 'Micrófono',
        cdPlayer: 'Reproductor de CD',
        dvdPlayer: 'Reproductor de DVD',
        usbPlayer: 'Reproductor de radio USB',
        heater: 'Calefacción',
        preHeater: 'Precalentador',
        kitchen: 'Cocina',
        coffeeMachine: 'Cafetera',
        refrigerator: 'Nevera',
        toilet: 'Aseo',
        safetyBelts: 'Cinturones de seguridad',
        acPassengers: 'Aire acondicionado para pasajeros',
        curtains: 'Parasoles',
        seatPocket: 'Red de estiba',
        footRest: 'Reposapiés',
        handRest: 'Reposabrazos',
        mp3: 'MP3',
        monitors: 'Monitores',
        seatsMonitor: 'Monitores de asiento',
        seatsRadio: 'Radio del asiento',
        seatsUsbPorts: 'USB a asientos',
        socket220: 'Toma de corriente conductor 220V',
        seatsSocket220: 'Tomas de corriente 220V asientos',
        extendableSeats: 'Asientos extensibles',
        recliningSeats: 'Asientos reclinables',
        coffeeTables: 'Mesas de asiento',
    },
    auction: {
        countdown: {
            startsIn: 'Comienza dentro de',
            endsIn: 'Finaliza dentro de',
            days: 'd',
            hours: 'h',
            minutes: 'm',
            seconds: 's',
        },
        inspectionExpiredModal: {
            title: 'Inspección obligatoria para la compra fuera de Italia',
            description: 'Este vehículo se encuentra en Italia y, según el Código de Circulación vigente, los vehículos con la inspección vencida no pueden ser exportados fuera del país. Puedes participar en la subasta y ganar, pero para obtener el vehículo, necesitarás:',
            item1: 'Realizar la revisión antes de la exportación',
            item2: 'Asumir los posibles costos previstos',
            item3: 'Completar el procedimiento de baja para exportación definitiva',
            askConfirm: '¿Deseas proceder igualmente a comprometer el importe mínimo?',
            confirmButton: 'Confirmar y proceder',
        },
        bail: {
            title: 'Vincula la fianza a participar en la subasta',
            description: 'Para participar en esta subasta, debe vincular su depósito de garantía a través de un método de pago válido.',
            amount: 'DEPÓSITO DE GARANTÍA',
            note: 'El depósito de puja será liberado al final de la subasta si usted no se encuentra entre los mejores postores.',
            subnote: 'Los depósitos de garantía no son iguales para todos los bienes y pueden variar en función de la base de la subasta',
            error: 'Se ha producido un error al procesar su transacción, por favor inténtelo de nuevo más tarde o póngase en contacto con soporte',
            submit: 'Vincular depósito de garantía',
        },
        bid: {
            title: 'Conviértete en el mejor postor ahora',
            description: 'Recuerda que la oferta única o máxima es vinculante.',
            autoBidEditTitle: 'Actualiza tu oferta automática',
            autoBidEditDescription: 'Recuerda que la oferta máxima es vinculante.',
            activeAutoBid: 'Oferta automática activa hasta',
            actualBid: 'Oferta actual',
            autoBidToggle: 'Oferta automática ({0})',
            autoBidLink: '¿qué es?',
            yourBid: 'Tu oferta',
            yourMaxBid: 'Tu oferta máxima',
            restoreMinBid: 'Restablecer',
            confirmSingleBid: 'Hacer tu oferta',
            confirmAutoBid: 'Activar oferta automática',
            confirmAutoBidUpdate: 'Actualizar oferta automática',
            cancel: 'Cancelar',
            success: '¡Oferta realizada con éxito!',
            outbid: 'Otro usuario ha superado tu oferta',
            expiredError: {
                title: 'La subasta está cerrada',
                description: 'No se pueden hacer más ofertas.',
            },
            bidError: {
                title: 'Se ha producido un error inesperado.',
                description: 'No se pudo realizar su puja. Actualice la página y vuelva a intentar realizar su puja.',
            },
        },
        bidButton: 'Hacer una oferta',
        bidButtonActiveAutoBid: 'Modificar puja automática',
        auctionPanel: {
            reservePrice: 'Precio de reserva',
            startingPrice: 'Puja inicial',
            current: 'Actual',
            wonAuction: '¡Subasta adjudicada!',
            state: {
                [AuctionState.Draft]: 'Proyecto de subasta',
                [AuctionState.ToBeApproved]: 'Subasta por aprobar',
                [AuctionState.ToBeScheduled]: 'Subasta por programar',
                [AuctionState.Waiting]: 'Subasta por publicar',
                [AuctionState.Expired]: 'Subasta finalizada',
                [AuctionState.Canceled]: 'Subasta cancelada',
            },
            maxBidder: '¡Tu puja es la mejor!',
            outBid: '¡Oferta superada!',
            negotiationInProgress: 'Negociación en curso',
            feeNotice: 'comisiones ({0})',
            feeNoticeLink: '¿qué son?',
            notice: {
                cantParticipate: {
                    [BackendErrorCode.CantBidScrapAuctionNotScrapyardCompany]: 'Subasta reservada a demoledores inscritos en el registro de gestión de residuos en cat. 5 y operadores de C.E.R. código 16.01.04.',
                    [BackendErrorCode.CantBidUserCompanyEqSellerCompany]: 'El vehículo está a la venta por su empresa.',
                    [BackendErrorCode.CantBidUnapprovedCompany]: 'No puedes hacer ofertas porque estamos revisando los documentos de tu empresa.',
                    [BackendErrorCode.CantBidCompanyUnderReview]: 'No puedes realizar ofertas porque estamos revisando los documentos de tu empresa.',
                    [BackendErrorCode.CantBidRegisteredCompany]: 'No puedes realizar ofertas porque estamos verificando los datos de tu empresa.',
                    [BackendErrorCode.CantBidUserWithoutCompany]: 'No puedes realizar ofertas porque no hemos encontrado ninguna empresa asociada a tu perfil.',
                    [BackendErrorCode.CantBidCompanyNotYetApprovedForScrap]: 'No puedes realizar ofertas porque estamos verificando tu registro en la gestión de residuos en cat. 5 y operadores de C.E.R. código 16.01.04.',
                    [BackendErrorCode.CantBidCompanyNotApprovedForScrap]: 'No puedes realizar ofertas porque tu empresa no está registrada en la gestión de residuos en cat. 5 y operadores de C.E.R. código 16.01.04.',
                    generic: 'No puede participar en esta subasta',
                },
                reservePriceNotReached: {
                    base: 'Precio de reserva {0}',
                    notReached: 'aún no alcanzado',
                },
                inspectionExpired: 'Para exportar este vehículo es necesario realizar la inspección.',
            },
        },
        needSupport: '¿Necesita ayuda?',
        contactUs: 'Contacta con el servicio de atención al cliente de Fleequid',
        draftConfirmation: {
            title: 'Estás en modo borrador',
            description: 'Revisa todos los detalles de la subasta y confirma la exactitud de los datos ingresados.',
            loginAction: 'Inicie confirme',
            action: 'Confirmar la subasta',
            success: {
                title: 'Has confirmado la subasta',
                description: 'La confirmación de los datos se ha enviado correctamente',
            },
        },
        multi: {
            title: 'Paquete de {count} vehículos',
            detailTitle: 'Especificaciones del {name}',
        },
    },
    auctions: {
        page: {
            asset: {
                [AssetType.Bus]: 'autobuses',
            },
            title: 'Descubre todos los {title} en Fleequid',
            results: '{total} subastas visibles',
            emptyStateTitle: 'No hay resultados',
            emptyStateText: 'Los filtros aplicados no produjeron resultados.',
            resetFilters: 'Eliminar filtros',
        },
        filters: {
            applied: 'Filtros aplicados',
            button: {
                label: 'Filtrar barras',
            },
            modal: {
                title: 'Filtrar subastas',
                back: 'Volver a resultados',
            },
            label: 'Filtros',
            class: 'Clase',
            brand: 'Marca, modelo, versión',
            brandFilter: 'Marca',
            brandFilterAnyOption: 'Todas las marcas',
            modelFilter: 'Modelo',
            modelFilterAnyOption: 'Todos los modelos',
            versionFilter: 'Versión',
            versionFilterAnyOption: 'Todas las versiones',
            antiPollution: 'Normativa anticontaminación',
            registration: 'Registro',
            yearFrom: 'A partir de (año)',
            yearTo: 'Hasta (año)',
            yearAnyOption: 'Cualquier año',
            kilometers: 'Kilometraje',
            kmFrom: 'A partir de',
            kmTo: 'Hasta',
            kmAnyOption: 'Cualquier kilometraje',
            state: {
                title: 'Estado de la subasta',
                all: 'Todos los estados',
                [AuctionState.Pending]: 'Subastas no iniciadas',
                [AuctionState.Running]: 'Subastas en curso',
                [AuctionState.Expired]: 'Subastas terminadas',
            },
            scrapping: {
                title: 'Restricciones de demolición',
                help: '¿Cuáles son las restricciones de demolición?',
                all: 'Todos los vehículos',
                [RequireScrappingFilter.requireScrapping]: 'Con desguace obligatorio',
                [RequireScrappingFilter.doNotRequireScrapping]: 'Sin desguace obligatorio',
            },
            fuel: 'Alimentación',
            length: 'longitud',
            lengthFrom: 'Desde',
            lengthTo: 'Hasta',
            lengthAnyOption: 'Cualquier longitud',
            empty: 'Sin filtro aplicado',
        },
        sorting: {
            title: 'Ordenar por',
            yearAsc: 'Fecha de matriculación menos reciente',
            yearDesc: 'Fecha de matriculación más reciente',
            priceAsc: 'Menos costoso',
            priceDesc: 'Más costoso',
            kmAsc: 'Kilometraje ascendente',
            kmDesc: 'Kilometraje descendente',
            defaultByState: {
                all: 'Orden predeterminado',
                [AuctionState.Running]: 'Fecha de cierre más cercana',
                [AuctionState.Expired]: 'Fecha de cierre más reciente',
                [AuctionState.Pending]: 'Fecha de inicio más cercana',
            },
        },
        appliedFilters: {
            from: 'de: {0}',
            to: 'a: {0}',
            reset: 'Eliminar filtros',
            appliedFiltersCounter: '{count} filtro activo | {count} filtros activos',
        },
    },
    homepage: {
        link: 'Inicio',
        hero: {
            title: {
                base: '{0} vehículos pesados',
                highlight: 'No existen',
            },
            subtitle: 'Compra el autobús que buscas en solo 7 días, tú decides el precio.',
        },
        why: {
            title: '¿Por qué Fleequid?',
            section: {
                purchase: 'Busca entre cientos de autobuses en todo el mundo y compra tu próximo vehículo en 7 días.',
                assistance: 'Te ayudamos a elegir con imágenes detalladas, información completa y soporte técnico de calidad.',
                fairness: 'El autobús se vende a su precio justo gracias al mecanismo de subasta.',
            },
        },
        stats: {
            title: 'Fleequid en cifras',
            section: {
                purchase: 'Autobuses vendidos en 3 meses',
                markets: 'Países en los que operamos',
                team: 'Miembros del equipo',
                registrations: 'Empresas registradas en 3 meses',
            },
        },
        howItWorks: {
            title: '¿Cómo funciona la subasta?',
            section: {
                register: {
                    title: '1. Registra tu empresa',
                    content: 'Introduce los datos de tu empresa en la plataforma.',
                },
                choose: {
                    title: '2. Elige tu autobús',
                    content: 'Explora las subastas activas y futuras para encontrar el vehículo de tus sueños. Usa los filtros, imágenes y especificaciones técnicas detalladas para ayudarte.',
                },
                bid: {
                    title: '3. Haz tu oferta',
                    content: 'Cuando la subasta esté activa, participa. Sigue las ofertas y haz una nueva si la tuya es superada. Recuerda: necesitamos confirmar que tu oferta es seria, así que añade un depósito con tarjeta de crédito para validarla.',
                },
                autobid: {
                    title: '4. Prueba la puja automática',
                    content: '¿Tienes miedo de perder la subasta? Establece una oferta máxima que estés dispuesto a pagar, y nosotros pujaremos automáticamente por ti para superar las ofertas de otros usuarios (respetando tu límite).',
                },
                closure: {
                    title: '5. Fin de la subasta',
                    content: '¿Has ganado? Realiza el pago a Fleequid, que actúa como intermediario de confianza: gestionamos los pagos y coordinamos todos los trámites administrativos durante la transferencia de propiedad del vehículo. ¿No ganaste? Tu depósito será reembolsado.',
                },
            },
        },
    },
    block: {
        support: {
            title: '¿Dudas o preguntas?',
            section: {
                business: { title: 'Información comercial' },
                press: { title: 'Oficina de prensa' },
                careers: { title: 'Trabaja con nosotros', label: 'Página de LinkedIn' },
            },
        },
        whoWeAre: {
            title: {
                base: 'Tenemos {0} de experiencia',
                highlight: 'Más de 40 años',
            },
            description: 'Fleequid nació de la experiencia de Basco, líder en el mercado internacional de compra y venta de autobuses usados. La rapidez, la concreción y el profesionalismo de Basco fueron la chispa para la innovación tecnológica de Fleequid.',
        },
    },
    faq: {
        title: 'Preguntas frecuentes',
        start: {
            base: 'Cómo comenzar',
            participate: {
                q: '¿Cómo se participa en una subasta por internet?',
                a: 'Para participar en las subastas por internet de Fleequid, hay que registrarse en el portal, en la página {0}, introduciendo los datos sobre tu empresa y enviando los documentos requeridos. Una vez hecho esto, puedes visitar las páginas de las subastas actuales y hacer una o varias pujas por un vehículo que quieras comprar. Las subastas se realizan de forma telemática y se pueden presentar ofertas hasta el cierre de la subasta. La subasta finaliza con la adjudicación del vehículo al usuario que haya presentado la puja más alta, siempre que esta sea igual o superior al precio de reserva o tras la aceptación de una puja inferior al precio de reserva por parte del vendedor.',
                link: 'https://fleequid.com/es/signup',
            },
            reservePrice: {
                q: '¿Qué es el precio de reserva?',
                a: 'El precio de reserva es el importe mínimo que se debe superar para que la subasta se cierre con éxito. Si no se alcanza durante la subasta, corresponde al vendedor del vehículo decidir si desea aceptar de todos modos la mejor oferta recibida. Es un importe más alto que la base de la subasta: lo decide el vendedor antes de la salida a subasta y no es visible para el público, que solo conoce el valor de la puja máxima realizada hasta ese momento.',
            },
            countries: {
                q: '¿En qué países se pueden comprar y vender autobuses a través de Fleequid?',
                a: 'Fleequid opera en Italia, en la Unión Europea y en varios países fuera de la UE, respetando la normativa de cada país. Si tienes alguna duda sobre si puedes utilizar Fleequid desde tu país, ponte en contacto con el servicio de atención al cliente escribiendo a {0}',
            },
            bureaucracy: {
                q: '¿Quién se ocupa de los traspasos de titularidad, de la nacionalización y, en general, de los aspectos relativos a la documentación?',
                a: 'Fleequid indicará al vendedor y al comprador cómo gestionar los aspectos relativos a la documentación y los pagos relacionados con el traspaso de titularidad en función de las particularidades de la compraventa en cuestión (entre empresas italianas, de la UE o de fuera de la UE) y según el papel propio de la plataforma en el proceso de compraventa. En cualquier caso, la matriculación del vehículo en el país de destino es responsabilidad del comprador.',
            },
            toBeScrapped: {
                q: '¿Qué son los vehículos para desguace?',
                a: 'Los vehículos para desguace son aquellos que, por haber dejado de ser aptos para la circulación y el transporte de personas, deben someterse necesariamente, por ley o reglamento de licitación, a un proceso de recuperación y reutilización, incluso parcial, de sus componentes como piezas de recambio. La participación en las subastas de estos vehículos solo está permitida a los desguaces inscritos en el registro de gestión de residuos de cat. 5 y gestores del código C.E.R. 16.01.04. Actualmente, el servicio de subasta de vehículos para desguace solo está disponible en Italia.',
            },
            vies: {
                q: '¿Qué es el VIES? ¿Por qué es obligatorio inscribirse?',
                a: 'El VIES (sistema de intercambio de información sobre el IVA) es un motor de búsqueda (no una base de datos) creado por la Comisión Europea para facilitar la compraventa entre empresas de los Estados miembros. Dado que es necesario estar inscrito en el VIES para poder realizar operaciones intracomunitarias, las empresas europeas que quieran registrarse en Fleequid deben necesariamente estar en dicho sistema.',
            },
            coc: {
                q: '¿Qué es el COC?',
                a: 'El COC, o Certificado de Conformidad (en inglés, Certificate of Conformity), es un documento que certifica qué requisitos técnicos y de seguridad, previstos por las normativas europeas, cumple el vehículo. El documento incluye diversa información relativa al vehículo, entre la cual se encuentran las especificaciones técnicas, el número de chasis, los datos de emisiones, la clase de homologación del vehículo y las normas de seguridad que el vehículo respeta. Es un documento requerido para matricular un vehículo en un país diferente de la Unión Europea. La disponibilidad de este certificado evita la necesidad de controles técnicos adicionales. La falta del COC en el momento de la venta podría, por el contrario, no solo impedir su matriculación sino también exponer al vendedor a sanciones.',
            },
        },
        sell: {
            base: 'Cómo vender',
            selling: {
                q: '¿Cómo puedo empezar a vender en Fleequid?',
                a: 'Si tienes un autobús para vender, ponte en contacto con nuestro servicio de atención al cliente escribiendo a {0} o rellena el formulario disponible en la página {1} introduciendo los datos del vehículo. También es necesario registrarse como vendedor en la plataforma.',
            },
            startingPrice: {
                q: '¿Cómo se define la base de la subasta?',
                a: 'La base de la subasta es el precio mínimo del vehículo por encima del cual los compradores potenciales pueden realizar sus ofertas o pujas en el Marketplace. La base de la subasta la calcula Fleequid en función de su evaluación técnica del vehículo y es necesariamente inferior al precio de reserva, que fija el vendedor.',
            },
            delivery: {
                q: '¿Cómo se realiza la entrega del vehículo?',
                a: 'Después de recibir el pago, el vendedor debe poner al comprador en condiciones de recoger el vehículo; el comprador debe proceder con la recogida de manera inmediata, la cual debe realizarse dentro de los diez días siguientes a la fecha en que Fleequid informe al comprador sobre la finalización de los trámites de baja en el país de origen. Por cada día de retraso posterior al plazo indicado, Fleequid aplicará una penalización.',
            },
            howLong: {
                q: '¿En cuánto tiempo se vende el autobús?',
                a: 'Cuando se sube un vehículo a la plataforma, se comunican la fecha de inicio y fin de la subasta, que fija Fleequid en función de las características específicas del vehículo que se va a vender, y el tiempo de exposición previo a la subasta. Si la venta no tuviera éxito, Fleequid seguiría teniendo derecho a realizar hasta tres subastas consecutivas en un total de 60 días.',
            },
            expiredInspection: {
                q: '¿Puedo vender un vehículo con la inspección vencida?',
                a: 'En Italia, para exportar un vehículo al extranjero, es necesario cancelarlo en el Registro Público de Automóviles (PRA). Una de las condiciones para la cancelación es que el vehículo tenga inspección vigente. Un vehículo no inspeccionado limita su comercialización únicamente al territorio italiano. Si se desea obtener más ofertas y, en consecuencia, un precio de venta más alto, es recomendable que el vendedor subaste el vehículo con la inspección técnica en vigor.',
            },
        },
        buy: {
            base: 'Cómo comprar',
            inspection: {
                q: '¿Se pueden inspeccionar los vehículos antes de pujar?',
                a: 'El usuario que vende puede decidir si ofrece la opción de una visita en persona al vehículo. Si lo permite, se establecerá un día en el que se podrán realizar visitas en persona y comprobaciones al vehículo directamente en las instalaciones del vendedor.',
            },
            claims: {
                q: '¿Cómo gestionáis los vicios ocultos y las reclamaciones?',
                a: 'Fleequid es un intermediario entre el vendedor y el comprador y, como tal, no es responsable de ningún defecto, incluidos los vicios ocultos, el desgaste, las irregularidades, la falta de conformidad para el uso, así como de los errores o inexactitudes en las declaraciones realizadas por el vendedor. Al publicar su anuncio en Fleequid, el vendedor se compromete a ser transparente y a dar información real sobre el estado del vehículo; al mismo tiempo, el comprador debe comprometerse a informar de cualquier anomalía en un plazo breve (3 días para los defectos aparentes) y en el plazo de un mes desde la recogida para los vicios ocultos del vehículo. Fleequid está a disposición del comprador para la tramitación de reclamaciones y establece condiciones específicas para ello.',
            },
            paymentMethods: {
                q: '¿Qué métodos de pago se aceptan?',
                a: 'Fleequid acepta transferencias bancarias y pagos con tarjeta de crédito. En concreto, la fianza solo se puede pagar con tarjeta de crédito, mientras que la compra del vehículo y el pago de las comisiones y cualquier posible gasto accesorio deben hacerse por transferencia bancaria.',
            },
            howPaymentWorks: {
                q: '¿Cómo funciona la fianza?',
                a: 'La fianza es la cantidad de dinero que el usuario paga a Fleequid al realizar una puja en una subasta, como garantía de la seriedad de la misma. Cuando la subasta termina, se devuelven todas las fianzas, excepto las del primer y segundo clasificado. Si quien ha ganado la subasta completa la compra del vehículo realizando la transferencia por el importe acordado (a más tardar 3 días después de la recepción de la factura correspondiente), tanto su fianza como la del segundo clasificado se devuelven. Si la persona que ha ganado la subasta no paga en los plazos acordados, perderá tanto el autobús como la fianza. En ese caso, el segundo clasificado será el nuevo ganador de la subasta de pleno derecho y, al comprar el vehículo mediante transferencia bancaria, se le devolverá la fianza.',
            },
            deposit: {
                q: '¿Cómo funciona el depósito de seguridad?',
                a: 'El depósito de seguridad es la suma de dinero que el usuario entrega a Fleequid al realizar una puja en la subasta, como garantía de la seriedad de la oferta. Cuando la subasta termina, todos los depósitos son liberados, excepto los de los ganadores del primer y segundo lugar. Si el ganador finaliza la compra del vehículo, realizando la transferencia bancaria por el monto acordado (a más tardar 3 días después de recibir la factura correspondiente), tanto su depósito como el del segundo postor serán liberados. Si el ganador no realiza el pago dentro del tiempo acordado, perderá tanto el autobús como el depósito de seguridad. En este caso, el segundo ganador se convertirá automáticamente en el nuevo ganador de la subasta y, al comprar el vehículo mediante transferencia bancaria, se le devolverá el depósito de seguridad.',
            },
            autoBid: {
                q: '¿Cómo funciona la Puja Automática?',
                a1: 'La Puja Automática te permite establecer una cantidad máxima que estás dispuesto a ofrecer por la subasta (excluyendo las comisiones).',
                a2: 'Cuando la activas, el sistema realiza inmediatamente la oferta mínima válida, considerando el precio de reserva y las ofertas actuales de otros usuarios. Posteriormente, si otros usuarios hacen ofertas, el sistema pujará automáticamente en tu nombre, incrementando la oferta hasta el importe máximo que has definido, tras lo cual la Puja Automática se desactivará.',
                a3: 'La Puja Automática también intenta alcanzar el precio de reserva para maximizar tus posibilidades de ganar la subasta.',
                a4: 'Si varios usuarios activan la Puja Automática con el mismo importe máximo, se colocará una oferta por el importe máximo para cada usuario, pero solo se considerará ganadora la del usuario que la activó primero. Recuerda que todas las ofertas realizadas son vinculantes, incluso si no son ganadoras.',
                a5: 'Ten en cuenta que no puedes cancelar una Puja Automática mientras esté activa, pero puedes aumentar su importe máximo.',
            },
            negotiation: {
                q: '¿Cómo funciona la negociación en una subasta cerrada?',
                a1: 'La negociación en una subasta cerrada permite que el comprador y el vendedor alcancen un acuerdo cuando la subasta finaliza sin que la puja más alta haya alcanzado el precio de reserva. Este proceso busca fomentar un acuerdo entre las partes, aumentando así las posibilidades de venta.',
                a2: 'La negociación se inicia automáticamente al cerrarse la subasta sin haber alcanzado el precio de reserva y dura un máximo de 72 horas.',
                a3: 'Una vez iniciada la negociación, tanto el comprador como el vendedor pueden ver sus propuestas respectivas, denominadas «oferta» y «solicitud»:',
                a3_1: 'La «oferta» es el precio (neto de {0}) que el comprador está dispuesto a pagar para adjudicarse la negociación. La oferta inicial del comprador para el vendedor es la puja más alta que se haya realizado durante la subasta.',
                a3_2: 'La «solicitud» es el precio más bajo (neto de {0}) que el vendedor está dispuesto a aceptar para concluir la negociación. La solicitud inicial del vendedor para el comprador es el precio de reserva de la subasta.',
                a4: 'Durante la negociación, en cualquier momento, tanto el vendedor como el comprador pueden:',
                a4_1: 'Modificar su propia propuesta: el vendedor reduce su solicitud y el comprador aumenta su oferta.',
                a4_2: 'Aceptar la propuesta de la otra parte.',
                a5: 'Además, en todo momento:',
                a5_1: 'El comprador puede bloquear su oferta actual. Esta acción es irreversible y, posteriormente, el vendedor podrá aceptarla o rechazarla, concluyendo así la negociación.',
                a5_2: 'El vendedor puede poner fin a la negociación, provocando que la subasta se cierre sin adjudicación.',
                a6: 'La negociación finaliza con éxito si una de las partes acepta la propuesta de la otra. Si no se llega a una aceptación en un plazo de 72 horas o si el vendedor da por terminada la negociación, se considerará que la subasta queda sin adjudicación.',
                fee: 'comisiones',
            },
        },
        contactUs: 'Esperamos que hayas encontrado todas las respuestas a tus preguntas en esta página, pero si no es así, estaremos encantados de ayudarte. Llámanos al {0}, o escríbenos a {1}.',
    },
    footer: {
        cookiePolicy: {
            base: 'Política de cookies',
            changeConsent: 'Cambiar su consentimiento',
            revokeConsent: 'Revocar su consentimiento',
        },
        explore: 'Navegar por',
        legal: 'Información legal',
        support: '¿Necesita ayudo?',
        vat: 'Numero CIF',
        backTop: 'Volver arriba',
    },
    menu: {
        buy: 'Comprar',
        sell: 'Vender',
        faq: 'F.A.Q.',
    },
    mktg: {
        whyFleequid: {
            title: '¿Por qué Fleequid?',
            deal: {
                title: 'Compra y venta simplificadas',
                description: 'Una plataforma tecnológica en lugar de negociaciones con partes indecisas',
            },
            processes: {
                title: 'Procesos rápidos',
                description: 'Fleequid reduce el tiempo y ahorra energía a su personal',
            },
            support: {
                title: 'Soporte transparente',
                description: 'Todo está claro, desde el registro hasta la entrega',
            },
        },
    },
    notifications: {
        goToAuction: 'Ve a la subasta',
        success: 'Éxito',
        warning: 'Atención',
        error: 'Error',
        genericError: 'Se ha producido un error. Por favor, inténtelo de nuevo más tarde.',
        tryAgain: 'Por favor, inténtelo de nuevo',
        userOutBid: {
            title: '¡Tu puja ha sido superada!',
            description: 'Tu puja de {prevBidAmount} por {subject} ha sido superada. El nuevo precio actual es {currentBidAmount}.',
            titleAutoBidTie: '¡Tu puja ha sido vencida!',
            descriptionAutoBidTie: 'Tu puja de {prevBidAmount} por {subject} ha sido vencida porque otro usuario había colocado previamente una Puja Automática con la misma cantidad que la tuya.',
            titleAutoBid: '¡Tu puja ha sido superada!',
            descriptionAutoBid: 'Tu puja de {prevBidAmount} por {subject} ha sido superada porque otro usuario había colocado previamente una Puja Automática con una cantidad superior a la tuya. El nuevo precio actual es {currentBidAmount}.',
            inAuction: 'esta subasta',
            linkedAuction: 'la subasta de {brand} {model} {version}',
        },
        userBailAuthorized: {
            title: '¡Estás autorizado para participar en la subasta!',
            description: 'Has sido autorizado para participar {subject}',
            inAuction: 'en esta subasta',
            linkedAuction: 'en la subasta de {brand} {model} {version}',
        },
        userMaxBidderRestored: {
            title: '¡Eres el mejor postor!',
            description: '¡Tu puja de {prevBidAmount} por {subject} es la más alta!',
            inAuction: 'esta subasta',
            linkedAuction: 'la subasta de {brand} {model} {version}',
        },
        auctionWon: {
            title: 'Subasta finalizada – ¡Has ganado!',
            description: 'Estamos muy contentos de informarte que has ganado {subject} gracias a tu puja de {currentPrice}. {reserve}',
            inAuction: 'esta subasta',
            linkedAuction: 'la subasta de {brand} {model} {version}',
            reservePriceNotReached: 'Desafortunadamente, el precio de reserva no se ha alcanzado. Nos pondremos en contacto con el vendedor para proponerle tu oferta y ver si está dispuesto a proceder con la venta. Te mantendremos informado sobre cualquier novedad.',
        },
        userWinningBid: {
            title: '¡Eres el mejor postor!',
            description: 'Tu puja de {amount} por {subject} ha sido realizada, y eres el mejor postor.',
            inAuction: 'esta subasta',
            linkedAuction: 'la subasta de {brand} {model} {version}',
        },
        userAutoBidMaxThresholdReached: {
            title: '¡Tu puja automática ha alcanzado su límite!',
            description: 'Tu puja automática de {amount} por {subject} ha alcanzado el límite máximo y ya no está activa.',
            inAuction: 'esta subasta',
            linkedAuction: 'la subasta de {brand} {model} {version}',
        },
        negotiationStarted: {
            title: '¡Negociación iniciada!',
            description: 'Ha comenzado la negociación para {subject}.',
            inAuction: 'esta subasta',
            linkedAuction: 'la subasta de {brand} {model} {version}',
        },
        negotiationExpired: {
            title: '¡Negociación expirada!',
            description: 'El tiempo para la {subject} ha expirado. Negociación finalizada sin éxito.',
            inAuction: 'negociación',
            linkedAuction: 'negociación de {brand} {model} {version}',
        },
        negotiationProposalByBuyer: {
            title: '¡Oferta modificada!',
            description: 'El comprador ha modificado su oferta a {proposalAmount} para la {subject}.',
            inAuction: 'negociación',
            linkedAuction: 'negociación de {brand} {model} {version}',
        },
        negotiationProposalBySeller: {
            title: '¡Solicitud modificada!',
            description: 'El vendedor ha modificado su solicitud a {proposalAmount} para la {subject}.',
            inAuction: 'negociación',
            linkedAuction: 'negociación de {brand} {model} {version}',
        },
        negotiationAcceptedByBuyer: {
            title: '¡Solicitud aceptada!',
            description: 'El comprador ha aceptado tu solicitud de {acceptedAmount} para la {subject}. Negociación finalizada con éxito.',
            inAuction: 'negociación',
            linkedAuction: 'negociación de {brand} {model} {version}',
        },
        negotiationAcceptedBySeller: {
            title: '¡Oferta aceptada!',
            description: 'El vendedor ha aceptado tu oferta de {acceptedAmount} para la {subject}. Negociación finalizada con éxito.',
            inAuction: 'negociación',
            linkedAuction: 'negociación de {brand} {model} {version}',
        },
        negotiationsDeclinedBySeller: {
            title: '¡Negociación terminada!',
            description: 'El vendedor ha rechazado de forma definitiva tu oferta de {declinedAmount} para la {subject}. Negociación finalizada sin éxito.',
            inAuction: 'negociación',
            linkedAuction: 'negociación de {brand} {model} {version}',
        },
        negotiationStoppedProposalsByBuyer: {
            title: '¡Última oferta!',
            description: 'El comprador ha declarado que su oferta final es de {maxBuyerProposal} para la {subject}. Ahora te corresponde a ti tomar la decisión final.',
            inAuction: 'negociación',
            linkedAuction: 'negociación de {brand} {model} {version}',
        },
    },
    privacy: {
        link: 'Política de privacidad',
    },
    seo: {
        auction: {
            title: '{asset} {brand} {model} {version} {vin}',
            description: 'Comprar {brand} {model} {version}, {asset} de segunda mano en Fleequid.com: participa en la subasta B2B de y puja por {asset} {vin} marca {brand}, modelo {model} y versión {version}.',
            ogTitle: '{asset} {brand} {model} {version}',
            ogDescription: '{year}, {euroNorm}, {kilometers}: Participa en la subasta B2B y haz una oferta por este {asset} usado directamente en línea en Fleequid.com.',
        },
        auctions: {
            title: 'venta de {0} de segunda mano',
            description: 'Comprar {0} de segunda mano en línea en Fleequid.com : participe fácilmente en las subastas B2B de los mejores {1} de ocasión en venta.',
        },
        cookiePolicy: {
            title: 'Política de Cookies',
            description: 'Política de cookies del portal Fleequid: lee cómo gestionamos las cookies que utilizamos para hacer cómoda la navegación.',
        },
        homepage: {
            title: 'Venta de Autobuses de Ocasión',
            description: 'Compra o vende autobuses y vehículos industriales en subasta: Únete a Fleequid y participa fácilmente en subastas B2B de los mejores vehículos industriales.',
        },
        privacyPolicy: {
            title: 'Política de privacidad',
            description: 'Política de privacidad del portal Fleequid: explicamos claramente cómo tratamos los datos de navegación y todos tus datos.',
        },
        signup: {
            title: 'Subastas de vehículos: registro',
            description: 'Regístrese ahora en Fleequid para participar de la forma más fácil y cómoda en subastas de vehículos industriales, autobuses y autocares nuevos y usados.',
            confirm: {
                title: 'Verificación de correo electrónico',
            },
        },
        termsAndConditions: {
            title: 'Términos y condiciones',
            description: 'Términos y condiciones de compraventa en Fleequid, el portal de subastas y compraventa online de vehículos industriales y autobuses.',
        },
        pricingSeller: {
            title: 'Comisiones del vendedor',
            description: 'Comisiones de subasta cobradas en Fleequid.com: detalles de precios y especificaciones sobre el método de cobro de comisiones en las subastas de venta.',
        },
        pricingBuyer: {
            title: 'Comisiones del comprador',
            description: 'Comisiones de subasta cobradas en Fleequid.com: detalles de precios y especificaciones sobre el método de cobro de comisiones relacionadas con las subastas de venta.',
        },
    },
    signup: {
        link: 'Registrarse',
        companyRegistration: 'Registre su empresa',
        subtitle: 'Introduce los datos de tu empresa y entra en un nuevo mundo de ofertas.',
        companyInfo: 'Datos de la empresa',
        companyDocuments: 'Documentos de la empresa',
        contactPerson: 'Datos de contacto de la empresa',
        accessCredentials: 'Credenciales de acceso',
        fleetHandling: 'Movimiento de la flota',
        addressAutofill: {
            title: 'Dirección actualizada',
            description: 'La dirección de la empresa ha sido ingresada a partir del número de identificación fiscal.',
        },
        fields: {
            address: 'Dirección',
            postCode: 'CÓDIGO POSTAL',
            city: 'Ciudad',
            province: 'Provincia',
            businessName: 'Nombre de la empresa',
            country: 'País',
            pec: 'PEC',
            sdi: 'Código IDE',
            vatId: {
                base: 'NIF/CIF/VAT ID',
                invalid: 'El NIF no es válido según los registros de VIES.',
                validationUnavailable: 'No es posible verificar el NIF para el país ingresado. Un representante de servicio al cliente verificará los datos al finalizar el registro.',
            },
            companyRegistrationReportFile: 'Cámara de Comercio Visura',
            firstname: 'Nombre',
            lastname: 'Apellidos',
            phone: 'Número de teléfono',
            type: {
                label: 'Tipo de operador',
                [UserType.Dealer]: 'Operador',
                [UserType.PeopleTransportOperator]: 'Operador de transporte de pasajeros',
                [UserType.Scrapyard]: 'Demoledor',
                [UserType.Other]: 'Otros',
            },
            email: 'Correo electrónico',
            password: 'Contraseña',
            confirmPassword: 'Confirmación de contraseña',
            stats: {
                sales: '¿Cuántos autobuses tiene previsto vender en {year}?',
                purchase: '¿Cuántos autobuses tiene previsto comprar en {year}?',
                unknown: 'No lo sé',
            },
            consent: 'Consenso',
            termsConditions: 'He leído y acepto las {0} del servicio.',
            vexatiousClauses: 'He leído y acepto las siguientes cláusulas:',
            privacy: 'He leído y acepto las {0}.',
        },
        hint: {
            phone: {
                text: 'Formato del número',
                rule: 'Por favor, introduzca el número de teléfono en el formato +39 1234567890',
            },
        },
        validations: {
            requiredSDIOrPec: 'Introduzca al menos un código IDE o una dirección PEC',
            companyRegistration: 'El archivo seleccionado es demasiado grande. El tamaño máximo permitido es {size} MB',
        },
        success: {
            title: 'Muchas gracias por completar el registro',
            p1: 'Sus datos se han recibido correctamente.',
            p2: 'En breve recibirá un correo electrónico de confirmación con un enlace en el que deberá hacer clic para verificar su dirección de correo electrónico.',
            p3: 'Por favor, compruebe también la carpeta de correo no deseado de su buzón ya que, debido a sus preferencias, el correo electrónico puede haber sido marcado como correo no deseado.',
            p4: 'Le invitamos a ver las subastas en línea y averiguar si hay algún vehículo de su interés. ¿Quiere vender sus autobuses en la próxima subasta? Póngase en contacto con nosotros en',
            p5: '¡Gracias por elegirnos!',
        },
        confirm: {
            success: {
                title: '¡Tu email ha sido verificado!',
                p1: 'Gracias por confirmar su {0} correo electrónico.',
                p2: 'Su cuenta ya está activa y puede acceder a todas las funciones de nuestro servicio.',
                p3: 'Si tienes alguna pregunta o necesitas ayuda, ponte en contacto con nuestro equipo de soporte en {email}. ¡Bienvenido a bordo!',
            },
            error: {
                title: 'Error en la verificación del correo electrónico',
                p1: 'Lo sentimos, pero la verificación de su correo electrónico no ha tenido éxito.',
                p2: 'Puede que haya habido un problema con el enlace de confirmación.',
                p3: 'Inténtalo de nuevo haciendo clic en el enlace de verificación que te hemos enviado. Si el problema persiste, ponte en contacto con nuestro equipo de asistencia para obtener ayuda.',
            },
            buyCta: 'Participar en subastas',
            hpCta: 'Volver a la página de inicio',
        },
    },
    termsConditions: {
        link: 'Términos y condiciones',
    },
    login: {
        email: 'Correo electrónico',
        password: 'Contraseña',
        formSubtitle: 'Introduce tus credenciales de Fleequid para participar en las subastas',
        forgotPassword: '¿Has olvidado tu contraseña?',
        unregistered: '¿No tienes una cuenta Fleequid?',
        signupNow: 'Regístrate ahora',
    },
    passwordReset: {
        request: {
            title: 'Restablecer contraseña',
            subTitle: 'Introduzca su correo electrónico y le enviaremos un mensaje para restablecer su contraseña.',
            submitButton: 'Enviar correo electrónico',
            onSuccessTitle: 'Solicitud aceptada',
            onSuccessDescription: 'Recibirá un correo electrónico con el procedimiento para completar el restablecimiento de la contraseña.',
        },
        reset: {
            title: 'Restablecer contraseña',
            description: 'Por favor, introduzca una nueva contraseña. Una vez confirmado el cambio se invalidarán todas las sesiones activas.',
            newPassword: 'Nueva contraseña',
            confirmNewPassword: 'Confirmar nueva contraseña',
            submitButton: 'Cambiar contraseña',
            updateSuccess: 'Contraseña cambiada con éxito',
            tokenExpired: 'El token de restablecimiento de contraseña ha caducado.',
            tokenInvalid: 'La contraseña no es válida.',
            startOverDescription: 'Vuelva a realizar el procedimiento de solicitud de restablecimiento de contraseña, se generará un nuevo testigo al mismo tiempo.',
            startOverButton: 'Inténtelo de nuevo',
        },
    },
    validation: {
        msg: {
            characters: 'Uno o más caracteres inválidos',
            email: 'El correo electrónico debe tener el formato usuario{0}dominio.ext',
            incomplete: '{0} incompleto',
            invalid: "{'@'} inválido",
            invalidBid: 'El importe introducido no es válido',
            missing: '{field} Falta',
            max: 'Longitud máxima {field} carácter | Longitud máxima {field} caracteres',
            min: 'Longitud mínima {count} carácter | Longitud mínima {count} caracteres',
            length: 'Longitud debe ser exactamente {count} carácter | Longitud debe ser exactamente {count} caracteres',
            passwordWeak: 'Contraseña demasiado débil',
            passwordMatch: 'Las contraseñas no coinciden',
            phone: 'Introduzca sólo números, espacios y el carácter +',
            recaptcha: 'Error de validación reCAPTCHA',
            required: '{count} obligatorio',
        },
        hint: {
            password: {
                text: 'Reglas para una contraseña eficaz',
                rules: {
                    length: 'Al menos 8 caracteres',
                    uppercase: '1 carácter en mayúscula',
                    lowercase: '1 carácter en minúscula',
                    symbol: '1 carácter especial',
                    number: '1 número',
                },
            },
        },
    },
    profile: {
        title: 'Tu perfil',
        companyData: {
            readOnlyDisclaimer: 'Los datos de la empresa son de sólo lectura, para solicitar un cambio póngase en contacto con nosotros en {count}.',
        },
        userData: {
            saveChanges: 'Confirmar nuevos datos',
            cancelChanges: 'Cancelar cambios',
            updateSuccess: 'Datos de contacto de la empresa guardados correctamente',
        },
        changePassword: {
            title: 'Cambiar contraseña',
            description: 'El cambio de contraseña invalidará todas las demás sesiones activas excepto la actual.',
            currentPassword: 'Contraseña actual',
            newPassword: 'Nueva contraseña',
            confirmNewPassword: 'Confirmar nueva contraseña',
            submitButton: 'Confirmar nueva contraseña',
            updateSuccess: 'Contraseña modificada correctamente',
        },
        changeEmail: {
            title: 'Cambiar email',
            description: 'Recibirás un correo electrónico con un enlace para confirmar el nuevo correo electrónico. El cambio de correo electrónico invalidará todas las sesiones activas.',
            password: 'Contraseña actual',
            email: 'Correo electrónico',
            submitButton: 'Confirmar nuevo email',
            updateSuccess: 'Email cambiado con éxito',
        },
    },
    sessionExpired: {
        title: 'Su sesión ha expirado',
        description: 'Su sesión ha sido invalidada porque sus credenciales de acceso han sido cambiadas. Por favor, inicie sesión con las nuevas credenciales.',
        close: 'Continuar',
    },
    nuxtSiteConfig: {
        description: 'Compra o vende autobuses y vehículos industriales en subasta: entra en Fleequid y participa fácilmente en las subastas B2B de los mejores vehículos industriales.',
    },
    negotiation: {
        underNegotiation: 'En negociación',
        numberOfBids: '{count} oferta total | {count} ofertas totales',
        yourRequest: 'Tu solicitud',
        sellerRequest: 'Solicitud del vendedor',
        yourOffer: 'Tu oferta',
        buyerOffer: 'Oferta del comprador',
        youDeclineOfferNotice: 'Has rechazado la oferta',
        sellerDeclineOfferNotice: 'El vendedor ha rechazado la oferta',
        expiredNotice: 'El tiempo de negociación ha expirado sin lograr un acuerdo.',
        openNoticeForSeller: 'No se alcanzó el precio de reserva. Aún dispones de {countdown} para negociar con el comprador ({moreInfoLink}).',
        openNoticeForBuyer: 'No se alcanzó el precio de reserva. Aún dispones de {countdown} para negociar con el vendedor ({moreInfoLink}).',
        underSellerReviewNoticeForSeller: 'El comprador ha seleccionado esta oferta como {finalOffer}. Aún tienes {countdown} para aceptarla o rechazarla ({moreInfoLink}).',
        underSellerReviewNoticeFinalOfferForSeller: 'la cantidad máxima que está dispuesto a ofrecer',
        underSellerReviewNoticeForBuyer: 'Has seleccionado esta oferta como {finalOffer}. El vendedor aún dispone de {countdown} para aceptarla o rechazarla ({moreInfoLink}).',
        underSellerReviewNoticeFinalOfferForBuyer: 'la cantidad máxima que estás dispuesto a ofrecer',
        moreInfoLink: 'más info',
        updateRequest: 'Modificar tu solicitud',
        updateOffer: 'Modificar tu oferta',
        acceptRequest: 'Aceptar la solicitud',
        acceptOffer: 'Aceptar la oferta',
        buyerStopProposals: 'Bloquear la oferta actual',
        sellerDecline: 'Terminar la negociación',
        cancel: 'Cancelar',
        proposalModal: {
            title: 'Negociar el precio',
            subtitle: 'Dispones de {countdown} para negociar la oferta.',
            resetProposal: 'restablecer',
            stopProposalsLabel: 'Cantidad máxima que estoy dispuesto a ofrecer',
            stopProposalsDescription: 'Si seleccionas esta opción, el vendedor ya no podrá negociar, sino únicamente aceptar o rechazar esta oferta.',
        },
        acceptModal: {
            titleForSeller: '¿Aceptas la oferta?',
            titleForBuyer: '¿Aceptas la solicitud?',
        },
        sellerDeclineModal: {
            title: 'Terminar la negociación',
            disclaimer: 'Al proceder, darás por finalizada la subasta y perderás las ofertas recibidas. Nuestro equipo se pondrá en contacto contigo para reprogramar la subasta.',
            confirmButton: 'Terminar la negociación',
        },
        buyerStopProposalsModal: {
            title: 'Bloquear la oferta actual',
            disclaimer: 'Al proceder, el vendedor ya no podrá negociar, sino únicamente aceptar o rechazar esta oferta.',
            confirmButton: 'Bloquear la oferta actual',
        },
        backToNegotiation: 'Negociar ahora',
        notifications: {
            proposalByBuyer: {
                title: '¡Oferta modificada!',
                description: 'Has cambiado tu oferta a {amount} durante la negociación.',
            },
            proposalBySeller: {
                title: '¡Solicitud modificada!',
                description: 'Has cambiado tu solicitud a {amount} durante la negociación.',
            },
            acceptByBuyer: {
                title: '¡Solicitud aceptada!',
                description: 'Has aceptado la solicitud del vendedor de {amount}. Negociación finalizada con éxito.',
            },
            acceptBySeller: {
                title: '¡Oferta aceptada!',
                description: 'Has aceptado la oferta del comprador de {amount}. Negociación finalizada con éxito.',
            },
            declinedBySeller: {
                title: '¡Negociación terminada!',
                description: 'Has rechazado de forma definitiva la oferta del comprador de {amount}. Negociación finalizada sin éxito.',
            },
            stopProposalsByBuyer: {
                title: '¡Oferta final confirmada!',
                description: 'Tu oferta final para la negociación es de {amount}. Espera la decisión final del vendedor.',
            },
            apiError: {
                title: 'Se ha producido un error inesperado.',
                description: 'No ha sido posible completar la operación solicitada. Actualiza la página e inténtalo de nuevo.',
            },
        },
    },
};

export default es;
