import en from './en';

import { AssetDoors, AssetEuroNorm, AssetFuel, AssetGearboxType, AssetPartPosition, AssetSeatUpholstery, AssetStateOfUse, AssetTachograph, AssetType, AssetUseType, AssetWheelchairRamp, AuctionState, BackendErrorCode, BusClass, CruiseControlType, DeckType, GasTanksMaterials, Glasses, RequireScrappingFilter, UserType, VehicleCondition } from '~/apiClient';

type i18nKeys = keyof typeof en;
type i18nValues = (typeof en)[i18nKeys];

const fr: Record<i18nKeys, i18nValues> = {
    account: {
        bids: {
            title: 'Vos enchères',
            emptyState: {
                base: "Vous n'avez pas encore placé d'offre dans une vente aux enchères,",
                cta: 'Participez aux ventes aux enchères',
            },
            filters: {
                bidPosition: {
                    base: "Position de l'enchère",
                    anyOption: "Toutes les positions d'enchère",
                    maxBidder: 'Votre enchère est la plus élevée',
                    outbid: 'Votre offre a été surenchérie',
                },
                state: {
                    base: 'État de la vente aux enchères',
                    anyOption: 'Touts les états de vente aux enchères',
                    [AuctionState.Expired]: 'Vente aux enchères clôturées',
                    [AuctionState.Running]: 'Ventes aux enchères en cours',
                },
            },
        },
        sales: {
            title: 'Vos ventes',
            emptyStateText: "Il n'y a pas d'enchères avec un vendeur associé à ce compte.",
        },
    },
    common: {
        logout: 'Déconnexion',
        login: 'Connexion',
        loginSignup: 'Connexion / Inscription',
        loginTitle: 'Connexion à Fleequid',
        profile: 'Votre profil',
        searchPlaceholder: 'Rechercher...',
        searchNoResults: 'Aucun résultat trouvé pour « {query} »',
        email: 'Adresse e-mail',
        yourAccount: 'Votre compte',
        language: 'Langue',
        showMore: 'Voir plus',
        showLess: 'Voir moins',
    },
    cookie: {
        name: 'Nom',
        provider: 'Fournisseur',
        purpose: 'Objet',
        expiry: 'Expiration',
        type: 'Type',
    },
    error: {
        backend: {
            [BackendErrorCode.InvalidEnumValue]: 'Type de données invalide',
            [BackendErrorCode.InvalidDate]: 'Format de date invalide',
            [BackendErrorCode.InvalidLength]: 'Longueur invalide. Vérifiez les caractères manquants ou supplémentaires dans la chaîne',
            [BackendErrorCode.NotAString]: 'Type de données invalide ; seules les lettres sont autorisées',
            [BackendErrorCode.EmptyString]: 'Information manquante. Veuillez fournir toutes les données requises',
            [BackendErrorCode.PrismaError]: "Les données n'ont pas pu être enregistrées ; veuillez essayer une valeur différente.",
            [BackendErrorCode.AuthAuthenticationVersionMismatch]: 'Votre session a expiré. Veuillez vous reconnecter pour continuer',
            [BackendErrorCode.AuthWrongCredentials]: 'Identifiants invalides',
            [BackendErrorCode.AuthNoUserByEmail]: "Il n'y a pas de compte associé à l'adresse e-mail saisie.",
            [BackendErrorCode.AuthWrongPassword]: 'Le mot de passe que vous avez saisi est incorrect.',
            [BackendErrorCode.UserIsBanned]: 'Votre compte a été suspendu. Contactez-nous pour plus de renseignements.',
            [BackendErrorCode.AuthEmailNotVerified]: "L'adresse e-mail saisie n'a pas encore été vérifiée. Consultez votre boîte de réception ou contactez-nous.",
            [BackendErrorCode.SignupUserAlreadyExists]: 'Adresse e-mail déjà enregistrée, si vous avez oublié votre mot de passe, essayez de le récupérer.',
            [BackendErrorCode.UploadFileError]: 'Erreur lors du téléchargement du fichier',
            [BackendErrorCode.IsNotTrue]: "Sélection manquante : choisissez l'option requise pour continuer",
            [BackendErrorCode.EntityNotFound]: 'Aucun résultat trouvé',
            [BackendErrorCode.PasswordResetEmailNotExists]: 'Aucun compte actif ne correspond à cette adresse e-mail',
            [BackendErrorCode.EmailChangeWrongPassword]: "Vous ne pouvez pas modifier l'adresse e-mail car le mot de passe est incorrect",
            [BackendErrorCode.CantBidUserCompanyEqSellerCompany]: "Cet actif provient de votre entreprise, vous ne pouvez pas l'acheter.",
            [BackendErrorCode.AuctionUpdateDenied]: 'Seuls les utilisateurs des entreprises vendeuses sont autorisés à effectuer cette action.',
            authentication: "Erreur d'authentification ; veuillez essayer de vous connecter à nouveau",
        },
        '404': {
            title: '404',
            subtitle: 'Oups! Page non trouvée',
            description: "Désolé, la page que vous avez demandée n'existe pas. Quoi qu'il en soit, vous trouverez beaucoup plus à explorer sur Fleequid.",
            button: "Retour à la page d'accueil",
        },
        '500': {
            title: '500',
            subtitle: 'Oups! Une erreur est survenue',
            description: 'Nous nous excusons pour ce désagrément. Nous travaillons déjà à la résolution du problème.',
            help: {
                base: 'En attendant, vous pouvez essayer plus tard ou {contactUs}',
                contactUs: "nous contacter à l'adresse {email} ou appeler le {phone} en cas d'urgence",
            },
            button: "Retour à la page d'accueil",
        },
        default: {
            title: 'Erreur',
            subtitle: 'Une erreur inattendue est survenue',
            description: 'Nous travaillons déjà à la résolution du problème.',
            button: "Retour à la page d'accueil",
        },
    },
    a11y: {
        auctionDetail: 'En savoir plus sur {name}',
    },
    adp: {
        imageGallery: 'Galerie complète ({totalImages})',
        videoGallery: 'Regarder les vidéos ({totalVideos})',
        imagePagination: 'Photo {0} sur {1}',
        videoPagination: 'Vidéo {0} sur {1}',
        papers: 'Papiers',
        registrationDocument: "Document d'immatriculation du véhicule",
        cocDocument: 'C.O.C.',
        cocDocumentAbsent: 'Le certificat de conformité n’est pas disponible.',
        maintenanceCard: "Historique de l'entretien",
        detailsTitle: 'Informations sur le véhicule | Informations sur les véhicules',
    },
    asset: {
        type: {
            [AssetType.Bus]: 'Bus | Bus',
        },
        class: {
            base: 'Classe',
            [BusClass.ClassOne]: 'Ville',
            [BusClass.ClassTwo]: 'Intercités',
            [BusClass.ClassThree]: 'Tourisme',
            [BusClass.ClassA]: 'Classe A',
            [BusClass.ClassB]: 'Classe B',
            [BusClass.SchoolBus]: 'Bus scolaire',
        },
        firstRegistration: 'Première immatriculation',
        kilometers: 'Kilomètres',
        antiPollution: 'Antipollution',
        euroNorm: {
            [AssetEuroNorm.Euro0]: 'Euro 0',
            [AssetEuroNorm.Euro1]: 'Euro 1',
            [AssetEuroNorm.Euro2]: 'Euro 2',
            [AssetEuroNorm.Euro3]: 'Euro 3',
            [AssetEuroNorm.Euro4]: 'Euro 4',
            [AssetEuroNorm.Euro5]: 'Euro 5',
            [AssetEuroNorm.Euro6]: 'Euro 6',
        },
        toBeScrapped: 'À mettre à la casse',
        expiredInspection: 'Inspection expirée',
        bundle: 'Lot',
        vehicleSpecs: 'Spécifications du véhicule',
        bundleSpecs: 'Spécifications du lot',
        vehiclesInBundle: 'Véhicules inclus dans le lot',
        vehicleIndex: 'Véhicule n°{index}',
        vehicleCount: '{count} véhicules',
        description: 'Description',
        version: 'Version',
        geographicalLocation: 'Lieu',
        vehicleCondition: {
            base: 'État du véhicule',
            [VehicleCondition.Blocked]: 'Bloqué',
            [VehicleCondition.Rollable]: 'Remorquable',
            [VehicleCondition.Running]: 'Opératif',
        },
        registrationDocumentCountry: 'Nationalité des documents',
        conditionsAndMaintenance: 'Conditions et Entretien',
        stateOfUse: {
            present: 'Présent',
            missing: 'Manquant',
            [AssetStateOfUse.Insufficient]: 'Insuffisant',
            [AssetStateOfUse.WorkingAndMaintained]: 'En état de fonctionnement et régulièrement entretenu',
            [AssetStateOfUse.LikeNew]: 'Comme neuf',
        },
        stateOfUseGearbox: "État d'usage de la boîte de vitesses",
        stateOfUseAirConditioner: "État d'usage du climatiseur",
        stateOfUseBreakingSystem: "État d'usage du système de freinage",
        stateOfUseDrivingOrgans: "État d'usage des organes de conduite",
        stateOfUseEngine: "État d'usage du moteur",
        stateOfUsePowerSystem: "État d'usage du circuit d'alimentation",
        stateOfUseTimingBelt: "État d'usage de la courroie de distribution",
        stateOfUseTransmissionDevices: "État d'usage de la turbine",
        stateOfUseTurbine: "État d'usage de la turbine",
        missingParts: 'Pièces manquantes',
        useType: {
            base: "Type d'utilisation",
            [AssetUseType.OwnUse]: 'Usage propre',
            [AssetUseType.ThirdPartRent]: 'Location à des tiers',
            [AssetUseType.Line]: 'Ligne',
        },
        enginePosition: 'Position du moteur',
        position: {
            [AssetPartPosition.Rear]: 'Arrière',
            [AssetPartPosition.Central]: 'Centrale',
            [AssetPartPosition.Frontal]: 'Avant',
        },
        length: 'Longueur',
        height: 'Hauteur',
        width: 'Largeur',
        vin: 'V.I.N.',
        converter: 'Convertisseur',
        visitDate: {
            base: 'Visible',
            fromTo: 'le {date} du {fromTime} au {toTime}',
        },
        inspectionExpiryDate: "Date d'expiration de l'inspection",
        doors: {
            base: 'Portières',
            [AssetDoors.Double]: '2',
            [AssetDoors.DoubleDouble]: '2+2',
            [AssetDoors.DoubleDoubleDouble]: '2+2+2',
            [AssetDoors.DoubleDoubleSingle]: '2+2+1',
            [AssetDoors.DoubleSingleSingle]: '2+1+1',
            [AssetDoors.Single]: '1',
            [AssetDoors.SingleDouble]: '1+2',
            [AssetDoors.SingleSingle]: '1+1',
            [AssetDoors.SingleDoubleSingle]: '1+2+1',
            [AssetDoors.SingleSingleSingle]: '1+1+1',
            [AssetDoors.SingleDoubleDouble]: '1+2+2',
        },
        seatUpholstery: {
            base: 'Tapisserie de siège',
            [AssetSeatUpholstery.Fabric]: 'Tissu',
            [AssetSeatUpholstery.Leather]: 'Cuir',
            [AssetSeatUpholstery.Plastic]: 'Plastique',
            [AssetSeatUpholstery.FabricLeather]: 'Tissu cuir',
        },
        totalCapacity: 'Capacité totale de passagers',
        seats: 'Sièges',
        seatsFromS1: "Sièges, y compris le siège du conducteur, à partir du certificat d'immatriculation S1",
        standingPlaces: 'Places debout',
        standingPlacesFromS2: "Place debout à partir du certificat d'immatriculation S2",
        foldingSeats: 'Strapontin',
        wheelchairPlaces: 'Places pour fauteuils roulants',
        companionSeats: 'Sièges passagers',
        wheelchairRamp: {
            base: "Rampe d'accès pour fauteuil roulant",
            [AssetWheelchairRamp.Absent]: 'Absent',
            [AssetWheelchairRamp.Electric]: 'Électrique',
            [AssetWheelchairRamp.Manual]: 'Manuel',
            [AssetWheelchairRamp.Electropneumatic]: 'Électro-pneumatique',
        },
        luggageCapacity: 'Capacité de bagages',
        passThroughLuggageCompartment: 'Compartiment à bagages traversant',
        hatBox: 'Boîte à chapeaux',
        transmission: 'Transmission',
        gearboxType: {
            base: 'Type de transmission',
            [AssetGearboxType.Auto]: 'Automatique',
            [AssetGearboxType.Manual]: 'Manuel',
            [AssetGearboxType.SemiAuto]: 'Semi-automatique',
        },
        gearboxBrand: 'Marque de la transmission',
        gearboxModel: 'Modèle de la transmission',
        glasses: {
            base: 'Vitres',
            [Glasses.Single]: 'Vitrage simple',
            [Glasses.Double]: 'Double vitrage',
        },
        openableWindows: 'Vitres opérationnelles',
        engine: 'Moteur',
        engineBrand: 'Marque du moteur',
        engineModel: 'Modèle du moteur',
        hp: 'Chevaux',
        kw: 'Puissance',
        displacement: 'Cylindrée',
        cylinders: 'Nombre de cylindres',
        fuel: {
            base: 'Carburant',
            [AssetFuel.CNG]: 'GNC',
            [AssetFuel.Electric]: 'Électrique',
            [AssetFuel.GPL]: 'GPL',
            [AssetFuel.Diesel]: 'Diesel',
            [AssetFuel.Gasoline]: 'Essence',
            [AssetFuel.Hybrid]: 'Hybride',
            [AssetFuel.Hydrogen]: 'Hydrogène',
        },
        fuelTankCapacity: 'Capacité du réservoir',
        gasTanks: "Réservoirs d'essence",
        gasTanksMaterial: {
            base: 'Matériau du réservoir',
            [GasTanksMaterials.Aluminum]: 'Aluminium',
            [GasTanksMaterials.Composite]: 'Composite',
            [GasTanksMaterials.Steel]: 'Acier',
        },
        batteryPower: 'Capacité de la batterie',
        chargingType: 'Type de charge',
        axlesTires: 'Essieux et pneus',
        wheelSize: 'Taille des roues',
        wheels: 'nombre de roues (simples ou doubles)',
        axles: 'Essieux',
        wheelsSizeAxle1: "Taille des pneus pour l'essieu 1",
        wheelsSizeAxle2: "Taille des pneus pour l'essieu 2",
        wheelsSizeAxle3: "Taille des pneus pour l'essieu 3",
        alloyWheels: 'Jantes en alliage',
        spareWheel: 'Roue de secours',
        firePreventionSystem: "Système d'alarme incendie",
        firePreventionSystemBrand: "Marque du système d'alarme incendie",
        abs: 'ABS',
        asr: 'ASR',
        retarder: 'Ralentisseur',
        breathalyzer: 'Éthylotest',
        tachograph: { base: 'Tachygraphe', [AssetTachograph.Absent]: 'Absent', [AssetTachograph.Analog]: 'Analogique', [AssetTachograph.Digital]: 'Numérique' },
        indicatorSigns: 'Panneaux de destination',
        indicatorSignsBrand: 'Marque des panneaux de destination',
        accessories: 'Accessoires',
        cruiseControl: {
            base: 'Régulateur de vitesse',
            [CruiseControlType.No]: 'Non',
            [CruiseControlType.Adaptive]: 'Adaptatif',
            [CruiseControlType.Standard]: 'Standard',
        },
        deckType: {
            base: 'Plancher',
            [DeckType.LowEntry]: 'Entrée faible',
            [DeckType.LowFloor]: 'Plancher bas',
            [DeckType.NormalFloor]: 'Plancher normal',
        },
        acDriver: 'Climatisation du conducteur',
        driverBerth: 'Poste de conduite',
        wifi: 'Wi-Fi',
        cctv: 'CCTV',
        internalCameras: 'Caméras internes',
        externalCameras: 'Caméras externes',
        retrocamera: 'Caméra de recul',
        microphone: 'Microphone',
        cdPlayer: 'Lecteur CD',
        dvdPlayer: 'Lecteur DVD',
        usbPlayer: 'Lecteur USB radio',
        heater: 'Radiateur',
        preHeater: 'Préchauffeur',
        kitchen: 'Cuisine',
        coffeeMachine: 'Machine à café',
        refrigerator: 'Réfrigérateur',
        toilet: 'Toilettes',
        safetyBelts: 'Ceintures de sécurité',
        acPassengers: 'Climatisation des passagers',
        curtains: 'Rideaux',
        seatPocket: 'Poche de siège',
        footRest: 'Repose-pieds',
        handRest: 'Accoudoir',
        mp3: 'MP3',
        monitors: 'Moniteurs',
        seatsMonitor: 'Moniteur de sièges',
        seatsUsbPorts: 'Port USB des sièges',
        socket220: 'Prise électrique du conducteur',
        seatsSocket220: 'Prise électrique du siège passager 220 V',
        seatsRadio: 'Radio des sièges',
        extendableSeats: 'Sièges extensibles',
        recliningSeats: 'Sièges inclinables',
        coffeeTables: 'Tables basses',
    },
    auction: {
        countdown: {
            startsIn: 'Commence dans',
            endsIn: 'Termine dans',
            days: 'j',
            hours: 'h',
            minutes: 'm',
            seconds: 's',
        },
        inspectionExpiredModal: {
            title: 'Inspection requise pour un achat hors d’Italie',
            description: 'Ce véhicule se trouve en Italie, et selon le Code de la route en vigueur, les véhicules dont le contrôle technique est expiré ne peuvent pas être exportés hors du pays. Vous pouvez participer à la vente aux enchères et gagner, mais pour obtenir le véhicule, vous devrez :',
            item1: 'Effectuer le contrôle technique avant l’exportation',
            item2: 'Assumer les éventuels coûts prévus',
            item3: 'Achever la procédure de radiation pour exportation définitive',
            askConfirm: 'Voulez-vous tout de même procéder à l’engagement du montant minimum ?',
            confirmButton: 'Confirmer et procéder',
        },
        bail: {
            title: "Joindre le dépôt de garantie pour participer à l'enchère",
            description: "Pour participer à cette enchère, vous devez joindre le dépôt de garantie à l'aide d'un moyen de paiement valide.",
            amount: 'DÉPÔT DE GARANTIE',
            note: "Le dépôt de garantie sera débloqué à la fin de la vente aux enchères si vous n'êtes pas parmi les plus offrants.",
            subnote: "Les dépôts de garantie ne sont pas les mêmes pour tous les actifs et peuvent varier en fonction de la base d'enchères",
            error: "Une erreur est survenue lors du traitement de la transaction, veuillez réessayer plus tard ou contacter l'assistance",
            submit: 'Joindre le dépôt de garantie',
        },
        bid: {
            title: 'Devenez le meilleur enchérisseur maintenant',
            description: "N'oubliez pas que l'enchère unique ou maximale est engageante.",
            autoBidEditTitle: 'Mettre à jour votre enchère automatique',
            autoBidEditDescription: "N'oubliez pas que l'enchère maximale est engageante.",
            activeAutoBid: "Enchère automatique active jusqu'à",
            actualBid: 'Enchère actuelle',
            autoBidToggle: 'Enchère automatique ({0})',
            autoBidLink: "Qu'est-ce que c'est?",
            yourBid: 'Votre enchère',
            yourMaxBid: 'Votre enchère maximale',
            restoreMinBid: 'Réinitialiser',
            confirmSingleBid: 'Placer votre enchère',
            confirmAutoBid: "Activer l'enchère automatique",
            confirmAutoBidUpdate: "Mettre à jour l'enchère automatique",
            cancel: 'Annuler',
            success: 'Enchère placée avec succès !',
            outbid: 'Un autre utilisateur a surenchéri',
            expiredError: {
                title: 'La vente aux enchères est terminée',
                description: 'Aucune autre enchère ne peut être placée.',
            },
            bidError: {
                title: 'Une erreur inattendue s’est produite.',
                description: 'Votre enchère n’a pas pu être enregistrée. Veuillez rafraîchir la page et réessayer de placer votre enchère.',
            },
        },
        bidButton: 'Faire une enchère',
        bidButtonActiveAutoBid: "Mettre à jour l'enchère automatique",
        auctionPanel: {
            reservePrice: 'Prix de réserve',
            startingPrice: 'Prix de départ',
            current: "L'enchère",
            wonAuction: 'actuelle a gagné !',
            state: {
                [AuctionState.Draft]: 'Projet de vente aux enchères',
                [AuctionState.ToBeApproved]: 'Vente aux enchères à approuver',
                [AuctionState.ToBeScheduled]: 'Vente aux enchères à programmer',
                [AuctionState.Waiting]: 'Vente aux enchères à publier',
                [AuctionState.Expired]: 'Vente aux enchères clôturée',
                [AuctionState.Canceled]: 'Vente aux enchères annulée',
            },
            maxBidder: 'Votre enchère est la plus élevée !',
            outBid: 'Votre offre a été surenchérie !',
            negotiationInProgress: 'Négociation en cours',
            feeNotice: 'frais ({0})',
            feeNoticeLink: 'what\xa0are\xa0they?',
            notice: {
                cantParticipate: {
                    [BackendErrorCode.CantBidScrapAuctionNotScrapyardCompany]: 'Vente aux enchères réservée aux travailleurs de la casse inscrits au registre des gestionnaires de déchets de cat.  5 et les gestionnaires du code C.E.R. 16.01.04.',
                    [BackendErrorCode.CantBidUserCompanyEqSellerCompany]: 'Le véhicule est mis en vente par votre entreprise.',
                    [BackendErrorCode.CantBidUnapprovedCompany]: "Vous ne pouvez pas placer d'enchères car nous examinons les documents de votre entreprise.",
                    [BackendErrorCode.CantBidCompanyUnderReview]: "Vous ne pouvez pas placer d'enchères car nous vérifions les documents de votre entreprise.",
                    [BackendErrorCode.CantBidRegisteredCompany]: "Vous ne pouvez pas placer d'enchères car nous vérifions les données de votre entreprise.",
                    [BackendErrorCode.CantBidUserWithoutCompany]: "Vous ne pouvez pas placer d'enchères car nous n’avons trouvé aucune entreprise associée à votre profil.",
                    [BackendErrorCode.CantBidCompanyNotYetApprovedForScrap]: "Vous ne pouvez pas placer d'enchères car nous vérifions votre inscription au registre de gestion des déchets (catégorie 5) et les gestionnaires du code C.E.R. 16.01.04.",
                    [BackendErrorCode.CantBidCompanyNotApprovedForScrap]: "Vous ne pouvez pas placer d'enchères car votre entreprise n’est pas inscrite au registre de gestion des déchets (catégorie 5) et les gestionnaires du code C.E.R. 16.01.04.",
                    generic: 'Vous ne pouvez pas participer à cette vente aux enchères',
                },
                reservePriceNotReached: {
                    base: 'Prix de réserve {0}',
                    notReached: 'pas encore atteint',
                },
                inspectionExpired: "Pour exporter ce véhicule il est nécessaire d'effectuer l'inspection.",
            },
        },
        needSupport: "Vous avez besoin d'aide ?",
        contactUs: 'Contacter le service client Fleequid',
        draftConfirmation: {
            title: 'Vous êtes en mode brouillon',
            description: 'Vérifiez tous les détails de l’enchère et confirmez l’exactitude des données saisies.',
            loginAction: 'Connectez-vous confirmer',
            action: 'Confirmer l’enchère',
            success: {
                title: 'Vous avez confirmé l’enchère',
                description: 'La confirmation des données a été envoyée avec succès',
            },
        },
        multi: {
            title: 'Lot de {count} véhicules',
            detailTitle: 'Spécifications du {name}',
        },
    },
    auctions: {
        page: {
            asset: {
                [AssetType.Bus]: 'bus | bus',
            },
            title: 'Découvrez tous les {title} sur Fleequid',
            results: '{total} ventes aux enchères visibles',
            emptyStateTitle: 'Aucun résultat',
            emptyStateText: "Les filtres appliqués n'ont produit aucun résultat.",
            resetFilters: 'Supprimer les filtres',
        },
        filters: {
            applied: 'Filtres appliqués',
            button: {
                label: 'Filtrer les ventes aux enchères',
            },
            modal: {
                title: 'Filtre Ventes aux enchères',
                back: 'Retour aux résultats',
            },
            label: 'Filtres',
            class: 'Classe',
            brand: 'Marque, modèle, version',
            brandFilter: 'Marque',
            brandFilterAnyOption: 'Toutes les marques',
            modelFilter: 'Modèle',
            modelFilterAnyOption: 'Tous les modèles',
            versionFilter: 'Version',
            versionFilterAnyOption: 'Toutes les versions',
            antiPollution: 'Antipollution',
            registration: 'Immatriculation',
            yearFrom: 'De (année)',
            yearTo: 'À (année)',
            yearAnyOption: 'Toutes les années',
            kilometers: 'Kilomètres',
            kmFrom: 'De',
            kmTo: 'À',
            kmAnyOption: 'Tout kilométrage',
            state: {
                title: "État de l'enchère",
                all: 'Tous les états',
                [AuctionState.Pending]: 'Enchères non commencées',
                [AuctionState.Running]: 'Enchères en cours',
                [AuctionState.Expired]: 'Enchères clôturées',
            },
            scrapping: {
                title: 'Restrictions de mise à la casse',
                help: 'Quelles sont les restrictions de mise à la casse ?',
                all: 'Tous les véhicules',
                [RequireScrappingFilter.requireScrapping]: 'Avec restrictions de mise à la casse',
                [RequireScrappingFilter.doNotRequireScrapping]: 'Sans restrictions de mise à la casse',
            },
            fuel: 'Carburant',
            length: 'Longueur',
            lengthFrom: 'De',
            lengthTo: 'À',
            lengthAnyOption: 'Toute longueur',
            empty: 'Aucun filtre appliqué.',
        },
        sorting: {
            title: 'Trier par',
            yearAsc: 'Enregistrement le plus ancien',
            yearDesc: 'Enregistrement le plus récent',
            priceAsc: 'Moins cher',
            priceDesc: 'Plus cher',
            kmAsc: 'Kilométrage croissant',
            kmDesc: 'Kilométrage décroissant',
            defaultByState: {
                all: 'Ordre par défaut',
                [AuctionState.Running]: 'Fin la plus proche',
                [AuctionState.Expired]: 'Fin la plus récente',
                [AuctionState.Pending]: 'Début le plus proche',
            },
        },
        appliedFilters: {
            from: 'de : {year}',
            to: 'à : {year}',
            reset: 'Réinitialiser les filtres',
            appliedFiltersCounter: '{count} filtre actif | {count} filtres actifs',
        },
    },
    homepage: {
        link: 'Accueil',
        hero: {
            title: {
                base: '{0} véhicules lourds',
                highlight: 'Ils n’existent pas',
            },
            subtitle: 'Achetez le bus que vous recherchez en seulement 7 jours, c’est vous qui fixez le prix.',
        },
        why: {
            title: 'Pourquoi Fleequid ?',
            section: {
                purchase: 'Recherchez parmi des centaines de bus dans le monde entier et achetez votre prochain véhicule en 7 jours.',
                assistance: 'Nous vous aidons à choisir avec des images détaillées, des informations complètes et un support technique de qualité.',
                fairness: "L'autobus est vendu à son juste prix grâce au mécanisme de l’enchère.",
            },
        },
        stats: {
            title: 'Fleequid en chiffres',
            section: {
                purchase: 'Bus vendus en 3 mois',
                markets: 'Pays où nous opérons',
                team: "Membres de l'équipe",
                registrations: 'Entreprises enregistrées en 3 mois',
            },
        },
        howItWorks: {
            title: 'Comment fonctionne l’enchère ?',
            section: {
                register: {
                    title: '1. Enregistrez votre entreprise',
                    content: 'Saisissez les informations relatives à votre entreprise sur la plateforme.',
                },
                choose: {
                    title: '2. Choisissez votre bus',
                    content: 'Parcourez les enchères en cours et à venir pour trouver le véhicule de vos rêves. Utilisez les filtres, les images et les spécifications techniques détaillées pour vous aider.',
                },
                bid: {
                    title: '3. Faites votre offre',
                    content: 'Lorsque l’enchère est active, participez ! Suivez les offres et surenchérissez si la vôtre est dépassée. N’oubliez pas : nous avons besoin d’une garantie que votre offre est sérieuse, alors ajoutez un dépôt avec votre carte de crédit pour la valider.',
                },
                autobid: {
                    title: '4. Essayez la surenchère automatique',
                    content: 'As-tu peur de perdre l’enchère ? Fixe une offre maximale que tu es prêt à proposer, nous surenchérissons automatiquement pour toi afin de dépasser les offres des autres utilisateurs (en respectant ta limite).',
                },
                closure: {
                    title: '5. Fin de l’enchère',
                    content: 'Vous avez gagné ? Effectuez le paiement à Fleequid, qui agit comme un intermédiaire de confiance : nous gérons les paiements et coordonnons toutes les démarches administratives pour le transfert de propriété du véhicule. Vous n’avez pas gagné ? Votre dépôt vous sera restitué.',
                },
            },
        },
    },
    block: {
        support: {
            title: 'Des doutes ou des questions ?',
            section: {
                business: { title: 'Informations commerciales' },
                press: { title: 'Bureau de presse' },
                careers: { title: 'Travailler avec nous', label: 'Page LinkedIn' },
            },
        },
        whoWeAre: {
            title: {
                base: "Nous avons {0} d'expérience",
                highlight: '40+ ans',
            },
            description: "Fleequid est né de l'expérience de Basco, leader du marché international de l'achat et de la vente d'autobus d'occasion. La rapidité, le pragmatisme et le professionnalisme de Basco ont été le moteur de l'innovation technologique de Fleequid.",
        },
    },
    faq: {
        title: 'Foire aux questions',
        start: {
            base: 'Comment commencer ?',
            participate: {
                q: 'Comment puis-je participer à une vente aux enchères en ligne ?',
                a: "Pour participer aux ventes aux enchères en ligne sur Fleequid, vous devez vous inscrire sur {0} en fournissant les informations de votre entreprise et en envoyant les documents requis. Une fois que vous avez fait cela, vous pouvez consulter les ventes aux enchères en cours et placer une ou plusieurs offres pour concourir pour l'achat d'un véhicule. Les ventes aux enchères se déroulent par voie électronique et vous pouvez enchérir jusqu'à leur clôture. La vente aux enchères se termine lorsque l'utilisateur qui a soumis l'enchère la plus élevée remporte le véhicule, à condition que l'offre soit au moins équivalente au prix de réserve, ou si le vendeur accepte une offre inférieure au prix de réserve. ",
                link: 'https://fleequid.com/en/signup',
            },
            reservePrice: {
                q: 'Quel est le prix de réserve ?',
                a: "Le prix de réserve est le montant minimum qui doit être dépassé pour que la vente aux enchères soit réussie. Si ce montant n'est pas atteint lors de la vente aux enchères, c'est au vendeur de décider s'il est prêt à accepter la meilleure offre qu'il a reçue. Le prix de réserve est un montant plus élevé que l'enchère de départ : il est fixé par le vendeur avant le début de la vente aux enchères et ne sera pas visible par le public, qui ne connaît que l'enchère la plus élevée faite jusqu'à ce moment.",
            },
            countries: {
                q: "Dans quels pays est-il possible d'acheter et de vendre des bus via Fleequid ?",
                a: "Fleequid opère en Italie, dans l'Union européenne et dans plusieurs pays non membres de l'UE, en respectant les réglementations de chaque pays. Si vous avez des doutes sur la possibilité d'utiliser Fleequid depuis votre pays, veuillez contacter notre service client à {0}.",
            },
            bureaucracy: {
                q: "Qui est responsable des transferts de propriété, de l'immatriculation des véhicules et de tous les aspects administratifs ?",
                a: "Fleequid informera le vendeur et l'acheteur sur la manière de gérer les aspects documentaires et les paiements liés au transfert de propriété, en fonction des spécificités de l'accord (entre entreprises italiennes, UE et hors UE) et du rôle de la plateforme dans le processus de vente. Cependant, l'immatriculation du véhicule dans le pays de destination est de la responsabilité de l'acheteur.",
            },
            toBeScrapped: {
                q: 'Quels sont les véhicules destinés à la mise à la casse ?',
                a: "Les véhicules destinés à la mise à la casse sont ceux qui, n'étant plus adaptés à la circulation et au transport de personnes, doivent nécessairement, selon les lois ou règlements en vigueur, subir un processus de récupération et de réutilisation partielle de leurs composants en tant que pièces détachées. La participation aux ventes aux enchères de ces véhicules n'est autorisée que pour les démanteleurs répertoriés dans le registre de gestion des déchets de la catégorie 5 et les gestionnaires du code C.E.R. 16.01.04. Pour le moment, les ventes aux enchères des véhicules destinés à la mise à la casse ne sont disponibles qu'en Italie.",
            },
            vies: {
                q: "Qu'est-ce que VIES ? Pourquoi est-il obligatoire d'y être inclus ?",
                a: "Il s'agit d'un moteur de recherche (et non d'une base de données) créé par la Commission européenne pour faciliter les ventes entre entreprises de l'UE. Il s'agit d'un moyen électronique de vérification des numéros d'identification en matière de TVA des opérateurs économiques immatriculés dans l'Union européenne pour les transactions transfrontalières de biens ou de services. Pour cette raison, les entreprises européennes qui souhaitent opérer sur Fleequid doivent nécessairement y être répertoriées.",
            },
            coc: {
                q: "Qu'est-ce que le COC ?",
                a: "Le COC, ou Certificat de Conformité, est un document qui atteste de la conformité d'un véhicule aux normes techniques et de sécurité définies par les réglementations européennes. Ce document inclut diverses informations liées au véhicule, telles que ses spécifications techniques, le numéro de châssis, les données d'émissions, la classe d'homologation et les normes de sécurité auxquelles le véhicule est conforme. Il est requis pour immatriculer un véhicule dans un autre pays de l'Union européenne, car la possession de ce document évite la nécessité de contrôles techniques supplémentaires. En revanche, l'absence du COC lors de la vente pourrait non seulement empêcher l'immatriculation, mais aussi exposer potentiellement le vendeur à des sanctions.",
            },
        },
        sell: {
            base: 'Comment vendre ?',
            selling: {
                q: 'Comment puis-je commencer à vendre sur Fleequid ?',
                a: "Si vous avez un bus à vendre, veuillez contacter notre service client en écrivant à l'adresse {0} ou en remplissant le formulaire disponible sur {1}, en fournissant des informations sur le véhicule que vous souhaitez vendre.  Vous devez également enregistrer votre entreprise en tant que vendeur sur la plateforme.",
            },
            startingPrice: {
                q: 'Comment le prix de départ est-il défini ?',
                a: "Le prix de départ est le prix minimum du véhicule au-dessus duquel les acheteurs potentiels peuvent enchérir ou faire des contre-offres sur la place de marché. L'enchère de départ est calculée par Fleequid sur la base de son évaluation technique du véhicule et doit être inférieure au prix de réserve, qui est, quant à lui, fixé par le vendeur.",
            },
            delivery: {
                q: 'Comment le véhicule est-il livré ?',
                a: "Après avoir reçu le paiement, le vendeur doit permettre à l'acheteur de récupérer le véhicule ; l'acheteur doit procéder rapidement au retrait, qui doit avoir lieu dans les dix jours suivant la date à laquelle Fleequid informe l'acheteur de l'achèvement des démarches de désenregistrement dans le pays d'origine. Pour chaque jour de retard au-delà du délai indiqué, Fleequid appliquera une pénalité.",
            },
            howLong: {
                q: 'Combien de temps faut-il pour que le bus soit vendu ?',
                a: "Lorsqu'un véhicule est présenté sur la plateforme, Fleequid partage avec le vendeur les dates de début et de fin de la vente aux enchères et les informations sur la visibilité avant ladite vente aux enchères. Ces dates sont établies par Fleequid en fonction des spécificités du véhicule à vendre. Si la vente n'est pas conclue, Fleequid aura toujours le droit de tenir jusqu'à 3 ventes aux enchères consécutives dans un délai total de 30 jours.",
            },
            expiredInspection: {
                q: "Est-il possible d'acheter un véhicule avec un contrôle technique expiré ?",
                a: "En Italie, pour exporter un véhicule à l'étranger, il doit être radié du Registre Public Automobile (PRA). L'une des conditions d'annulation est que le véhicule ait un CT valide. Un véhicule non inspecté limite sa commercialisation au seul territoire italien. Si vous souhaitez obtenir plus d'offres et, par conséquent, un prix de vente plus élevé, il est conseillé au vendeur de mettre aux enchères le véhicule avec le CT valide.",
            },
        },
        buy: {
            base: 'Comment acheter ?',
            inspection: {
                q: "Est-il possible d'inspecter les véhicules avant d'enchérir ?",
                a: "The seller can decide whether to offer the option of live inspection of the vehicle. If allowed, the seller will define a specific day for live visits and inspections of the vehicle, directly at the seller's location.",
            },
            claims: {
                q: 'Comment gérez-vous les vices cachés et les réclamations ?',
                a: "Fleequid agit en tant qu'intermédiaire entre le vendeur et l'acheteur et, à ce titre, n'est pas responsable des défauts, qu'ils soient cachés ou visibles, des défauts, de l'usure, des irrégularités, de l'inaptitude à l'utilisation, ainsi que des erreurs ou des inexactitudes dans les déclarations faites par le vendeur. En publiant une vente aux enchères sur Fleequid, le vendeur s'engage à faire preuve de transparence et à fournir des informations précises sur l'état du véhicule ; en même temps, l'acheteur doit s'engager à signaler tout écart dans les meilleurs délais (3 jours pour les défauts apparents) dans un délai d'un mois à compter de la prise en charge pour les vices cachés du véhicule. Fleequid est disponible pour aider les acheteurs à gérer les réclamations et fournit des conditions particulières pour leur traitement.",
            },
            paymentMethods: {
                q: 'Quels sont les modes de paiement acceptés ?',
                a: "Fleequid accepte les virements bancaires et les paiements par carte de crédit. Plus précisément, le dépôt de garantie ne doit être payé que par carte de crédit. L'achat du véhicule, le paiement des commissions et les éventuels frais supplémentaires doivent être effectués par virement bancaire. ",
            },
            howPaymentWorks: {
                q: 'Comment fonctionne le paiement ?',
                a: "L'utilisateur qui remporte l'enchère doit payer un montant qui comprend le prix de l'enchère gagnante, les frais de vente pour Fleequid et tous autres frais convenus (voir les Conditions Générales pour plus de détails). L'acheteur doit régler le montant dû par virement bancaire au plus tard trois jours après réception de la facture correspondante. Les paiements partiels ne sont pas autorisés.",
            },
            deposit: {
                q: 'Comment fonctionne le dépôt de garantie ?',
                a: "Le dépôt de garantie est la somme d'argent que l'utilisateur verse à Fleequid lorsqu'il enchérit, comme garantie du sérieux de l'offre. À la fin de la vente aux enchères, tous les dépôts sont libérés, à l'exception de ceux des gagnants de la première et de la deuxième place. Si le gagnant finalise l'achat du véhicule, en effectuant le virement bancaire pour le montant convenu (au plus tard 3 jours après la réception de la facture correspondante), son dépôt et celui du deuxième enchérisseur seront libérés. Si le gagnant n'effectue pas le paiement dans les délais convenus, il perdra à la fois le bus et le dépôt de garantie. Dans ce cas, le gagnant de la deuxième place deviendra automatiquement le nouveau gagnant de l'enchère et, après avoir acheté le véhicule par virement bancaire, son dépôt de garantie lui sera restitué.",
            },
            autoBid: {
                q: 'Comment fonctionne l’Enchère Automatique?',
                a1: 'L’Enchère Automatique vous permet de définir une offre maximale que vous êtes prêt à proposer pour l’enchère (hors commissions).',
                a2: 'Lorsque vous l’activez, le système effectue immédiatement l’offre minimale valide, en tenant compte du prix de réserve et des offres actuelles des autres utilisateurs. Par la suite, si d’autres utilisateurs font des offres, le système surenchérira automatiquement en votre nom, augmentant l’offre jusqu’au montant maximum que vous avez défini, après quoi l’Enchère Automatique se désactivera.',
                a3: 'L’Enchère Automatique vise également à atteindre le prix de réserve pour maximiser vos chances de remporter l’enchère.',
                a4: 'Si plusieurs utilisateurs activent l’Enchère Automatique avec le même montant maximum, une offre du montant maximum sera placée pour chaque utilisateur, mais seule celle de l’utilisateur qui l’a activée en premier sera considérée comme gagnante. N’oubliez pas que toutes les offres placées sont contraignantes, même si elles ne sont pas gagnantes.',
                a5: 'Veuillez noter que vous ne pouvez pas annuler une Enchère Automatique active, mais vous pouvez uniquement augmenter son montant maximum.',
            },
            negotiation: {
                q: 'Comment fonctionne la négociation en enchère fermée ?',
                a1: 'La négociation en enchère fermée permet à l’acheteur et au vendeur de parvenir à un accord lorsque l’enchère se termine sans que l’offre la plus élevée n’ait atteint le prix de réserve. Ce processus vise à encourager un accord entre les parties, augmentant ainsi les chances de vente.',
                a2: 'La négociation commence automatiquement à la clôture de l’enchère si le prix de réserve n’est pas atteint, et dure jusqu’à 72 heures.',
                a3: 'Une fois la négociation lancée, l’acheteur comme le vendeur peuvent voir leurs propositions respectives, appelées « offre » et « demande » :',
                a3_1: 'L’« offre » est le prix (hors {0}) que l’acheteur est prêt à payer pour obtenir la négociation. L’offre initiale de l’acheteur pour le vendeur correspond à l’offre la plus élevée qui a été placée pendant l’enchère.',
                a3_2: 'La « demande » est le prix le plus bas (hors {0}) que le vendeur est disposé à accepter pour conclure la négociation. La demande initiale du vendeur pour l’acheteur est le prix de réserve de l’enchère.',
                a4: 'Pendant la négociation, à tout moment, le vendeur et l’acheteur peuvent :',
                a4_1: 'Modifier leur propre proposition, le vendeur en baissant sa demande et l’acheteur en augmentant son offre.',
                a4_2: 'Accepter la proposition de l’autre partie.',
                a5: 'En outre, à tout instant :',
                a5_1: 'L’acheteur peut verrouiller son offre actuelle. Cette action est irréversible et, par la suite, le vendeur peut l’accepter ou la refuser, mettant ainsi fin à la négociation.',
                a5_2: 'Le vendeur peut mettre fin à la négociation, entraînant la clôture de l’enchère sans adjudication.',
                a6: 'La négociation se conclut avec succès si l’une des parties accepte la proposition de l’autre. Si aucune acceptation n’intervient dans un délai de 72 heures, ou si le vendeur met fin à la négociation, l’enchère est considérée comme non adjugée.',
                fee: 'frais',
            },
        },
        contactUs: "Nous espérons que vous avez trouvé toutes les réponses à vos questions sur cette page, mais si ce n'est pas le cas, nous serons toujours heureux de vous aider. Appelez-nous au {0} ou contactez-nous en écrivant à l'adresse {1}.",
    },
    footer: {
        cookiePolicy: {
            base: 'Politique relative aux cookies',
            changeConsent: 'Modifier votre consentement',
            revokeConsent: 'Révoquer votre consentement',
        },
        explore: 'Explorer',
        legal: 'Pages juridiques',
        support: "Vous avez besoin d'aide ?",
        vat: 'Numéro de TVA',
        backTop: 'Haut de page',
    },
    menu: {
        buy: 'Acheter',
        sell: 'Vendre',
        faq: 'F.A.Q.',
    },
    mktg: {
        whyFleequid: {
            title: 'Pourquoi choisir Fleequid ?',
            deal: {
                title: 'Offres faciles',
                description: 'Une plateforme technologique au lieu de négociations avec des interlocuteurs indécis',
            },
            processes: {
                title: 'Processus rapides',
                description: "Fleequid permet à votre équipe d'économiser du temps et de l'énergie",
            },
            support: {
                title: 'Une assistance transparente',
                description: "De l'enregistrement à la livraison du véhicule, tout est clair",
            },
        },
    },
    notifications: {
        goToAuction: 'Aller à la vente aux enchères',
        success: 'Réussite',
        warning: 'Attention',
        error: 'Erreur',
        genericError: 'Une erreur est survenue. Veuillez réessayer ultérieurement.',
        tryAgain: 'Veuillez réessayer',
        userOutBid: {
            title: 'Votre enchère a été surpassée !',
            description: 'Votre enchère de {prevBidAmount} pour {subject} a été surpassée. Le nouveau prix actuel est {currentBidAmount}.',
            titleAutoBidTie: 'Votre enchère a été battue !',
            descriptionAutoBidTie: 'Votre enchère de {prevBidAmount} pour {subject} a été battue car un autre utilisateur avait précédemment placé une Enchère Automatique avec le même montant.',
            titleAutoBid: 'Votre enchère a été surpassée !',
            descriptionAutoBid: 'Votre enchère de {prevBidAmount} pour {subject} a été surpassée car un autre utilisateur avait précédemment placé une Enchère Automatique avec un montant supérieur au vôtre. Le nouveau prix actuel est de {currentBidAmount}.',
            inAuction: 'cette vente aux enchères',
            linkedAuction: 'la vente aux enchères de {brand} {model} {version}',
        },
        userBailAuthorized: {
            title: 'Vous êtes autorisé à participer à la vente aux enchères !',
            description: 'Vous avez été autorisé à participer {subject}',
            inAuction: 'à cette vente aux enchères',
            linkedAuction: 'à la vente aux enchères de {brand} {model} {version}',
        },
        userMaxBidderRestored: {
            title: 'Vous êtes le meilleur enchérisseur !',
            description: 'Votre enchère de {prevBidAmount} pour {subject} est la plus élevée !',
            inAuction: 'cette vente aux enchères',
            linkedAuction: 'la vente aux enchères de {brand} {model} {version}',
        },
        auctionWon: {
            title: 'Vente terminée – Vous avez gagné !',
            description: 'Nous sommes ravis de vous informer que vous avez remporté {subject} grâce à votre enchère de {currentPrice}. {reserve}',
            inAuction: 'cette vente aux enchères',
            linkedAuction: 'la vente aux enchères de {brand} {model} {version}',
            reservePriceNotReached: 'Malheureusement, le prix de réserve n’a pas été atteint. Nous contacterons le vendeur pour lui proposer votre offre et voir s’il est disposé à procéder à la vente. Nous vous tiendrons informé de tout développement.',
        },
        userWinningBid: {
            title: 'Vous êtes le meilleur enchérisseur !',
            description: 'Votre enchère de {amount} pour {subject} a été placée, et vous êtes le meilleur enchérisseur.',
            inAuction: 'cette vente aux enchères',
            linkedAuction: 'la vente aux enchères de {brand} {model} {version}',
        },
        userAutoBidMaxThresholdReached: {
            title: 'Votre enchère automatique a atteint sa limite !',
            description: 'Votre enchère automatique de {amount} pour {subject} a atteint la limite maximale et n’est plus active.',
            inAuction: 'cette vente aux enchères',
            linkedAuction: 'la vente aux enchères de {brand} {model} {version}',
        },
        negotiationStarted: {
            title: 'Négociation commencée!',
            description: 'La négociation pour {subject} a démarré.',
            inAuction: 'cette enchère',
            linkedAuction: "l'enchère de {brand} {model} {version}",
        },
        negotiationExpired: {
            title: 'Négociation expirée!',
            description: 'Le temps alloué à la {subject} est écoulé. Négociation close sans succès.',
            inAuction: 'négociation',
            linkedAuction: 'négociation pour {brand} {model} {version}',
        },
        negotiationProposalByBuyer: {
            title: 'Offre modifiée!',
            description: "L'acheteur a modifié son offre à {proposalAmount} pour la {subject}.",
            inAuction: 'négociation',
            linkedAuction: 'négociation pour {brand} {model} {version}',
        },
        negotiationProposalBySeller: {
            title: 'Demande modifiée!',
            description: 'Le vendeur a modifié sa demande à {proposalAmount} pour la {subject}.',
            inAuction: 'négociation',
            linkedAuction: 'négociation pour {brand} {model} {version}',
        },
        negotiationAcceptedByBuyer: {
            title: 'Demande acceptée!',
            description: "L'acheteur a accepté votre demande de {acceptedAmount} pour la {subject}. Négociation conclue avec succès.",
            inAuction: 'négociation',
            linkedAuction: 'négociation pour {brand} {model} {version}',
        },
        negotiationAcceptedBySeller: {
            title: 'Offre acceptée!',
            description: 'Le vendeur a accepté votre offre de {acceptedAmount} pour la {subject}. Négociation conclue avec succès.',
            inAuction: 'négociation',
            linkedAuction: 'négociation pour {brand} {model} {version}',
        },
        negotiationsDeclinedBySeller: {
            title: 'Négociation terminée!',
            description: 'Le vendeur a définitivement refusé votre offre de {declinedAmount} pour la {subject}. Négociation close sans succès.',
            inAuction: 'négociation',
            linkedAuction: 'négociation pour {brand} {model} {version}',
        },
        negotiationStoppedProposalsByBuyer: {
            title: 'Dernière offre!',
            description: "L'acheteur a déclaré que son offre finale s'élève à {maxBuyerProposal} pour la {subject}. La décision finale vous appartient.",
            inAuction: 'négociation',
            linkedAuction: 'négociation pour {brand} {model} {version}',
        },
    },
    privacy: {
        link: 'Politique de confidentialité',
    },
    seo: {
        auction: {
            title: '{asset} {brand} {model} {version} {vin} aux enchères',
            description: "Achetez {brand} {model} {version}, un {asset} d'occasion sur Fleequid.com : rejoignez la vente aux enchères B2B et placez une offre pour le {asset} {vin} de la marque {brand}, du modèle {model} et de la version {version}.",
            ogTitle: '{asset} {brand} {model} {version} {vin} aux enchères',
            ogDescription: '{year}, {euroNorm}, {kilometers} : Participez à l’enchère B2B et faites une offre pour ce {asset} d’occasion directement en ligne sur Fleequid.com.',
        },
        auctions: {
            title: "{0} d'occasion en vente",
            description: "Achetez en ligne des {0} d'occasion sur Fleequid.com : participez facilement aux ventes aux enchères B2B des meilleurs {1} d'occasion aux enchères.",
        },
        cookiePolicy: {
            title: 'Politique relative aux cookies',
            description: 'En savoir plus sur la gestion des cookies sur le portail Fleequid : découvrez comment nous utilisons les cookies pour améliorer votre expérience de navigation',
        },
        homepage: {
            title: 'Bus à vendre',
            description: 'Achetez ou vendez des bus et des véhicules commerciaux par le biais de ventes aux enchères : rejoignez Fleequid et participez sans effort à des ventes aux enchères B2B de premier ordre de véhicules industriels',
        },
        privacyPolicy: {
            title: 'Politique de confidentialité',
            description: 'Politique de confidentialité concernant le portail Fleequid : nous expliquons clairement comment nous traitons les données de navigation et toutes vos informations.',
        },
        signup: {
            title: 'Ventes aux enchères de véhicules en ligne : inscription',
            description: "Inscrivez-vous dès maintenant sur Fleequid pour participer facilement et commodément aux ventes aux enchères de véhicules industriels, de bus et d'autocars neufs et d'occasion.",
            confirm: {
                title: "Vérification de l'adresse e-mail",
            },
        },
        termsAndConditions: {
            title: 'Conditions générales',
            description: "Conditions générales de vente et d'achat sur Fleequid, le portail en ligne de vente aux enchères et d'achat de véhicules industriels et de bus.",
        },
        pricingSeller: {
            title: 'Prix vendeur',
            description: "Frais d'enchères sur Fleequid.com : prix détaillés et informations sur la méthode d'application des frais d'enchère.",
        },
        pricingBuyer: {
            title: 'Prix acheteur',
            description: "Frais d'enchères sur Fleequid.com : prix détaillés et informations sur la méthode d'application des frais d'enchère.",
        },
    },
    signup: {
        link: 'Inscription',
        companyRegistration: 'Inscrivez votre entreprise',
        subtitle: 'Remplissez les informations de votre entreprise et ajoutez de nouveaux actifs à votre flotte.',
        companyInfo: "Informations sur l'entreprise",
        companyDocuments: "Documents de l'entreprise",
        contactPerson: "Personne de contact de l'entreprise",
        accessCredentials: "Informations d'accès",
        fleetHandling: 'Manutention de la flotte',
        addressAutofill: {
            title: 'Adresse mise à jour',
            description: "L'adresse de l'entreprise a été renseignée à partir du numéro de TVA.",
        },
        fields: {
            address: 'Adresse',
            postCode: 'Code postal',
            city: 'Ville',
            province: 'Province/région',
            businessName: 'Raison sociale',
            country: 'Pays',
            pec: 'Adresse e-mail certifiée',
            sdi: 'Numéro SDI',
            vatId: {
                base: 'Numéro de TVA',
                invalid: 'Le numéro de TVA semble incorrect.',
                validationUnavailable: "Nous n'avons pas pu vérifier le numéro de TVA pour le pays que vous avez saisi. Un représentant du service clientèle examinera vos informations une fois que vous aurez terminé le processus d'inscription.",
            },
            companyRegistrationReportFile: "Rapport d'immatriculation de l'entreprise",
            firstname: 'Prénom',
            lastname: 'Nom de famille',
            phone: 'Numéro de téléphone',
            type: {
                label: "Type d'opérateur",
                [UserType.Dealer]: 'Vendeur',
                [UserType.PeopleTransportOperator]: 'Transport routier de voyageurs',
                [UserType.Scrapyard]: 'Travailleur de la casse',
                [UserType.Other]: 'Autre',
            },
            email: 'Adresse e-mail',
            password: 'Mot de passe',
            confirmPassword: 'Confirmez le mot de passe',
            stats: {
                sales: 'Combien de bus pensez-vous vendre en {year} ?',
                purchase: "Combien de bus d'occasion pensez-vous acheter en {year} ?",
                unknown: "I don't know",
            },
            consent: 'Consentement',
            termsConditions: "J'ai lu et j'accepte les {0}.",
            vexatiousClauses: "J'ai lu et j'accepte les clauses suivantes :",
            privacy: "J'ai lu et j'accepte les {0}.",
        },
        hint: {
            phone: {
                text: 'Format du numéro',
                rule: 'Saisissez le numéro de téléphone au format +39 1234567890',
            },
        },
        validations: {
            requiredSDIOrPec: 'Saisissez au moins un numéro SDI ou une adresse e-mail certifiée (PEC)',
            companyRegistration: 'Le fichier sélectionné est trop lourd. La taille maximale de fichier autorisée est de {size} Mo',
        },
        success: {
            title: "Merci d'avoir terminé l'enregistrement!",
            p1: 'Vos données ont été reçues avec succès.',
            p2: 'Vous recevrez bientôt un e-mail de confirmation avec un lien sur lequel cliquer pour vérifier votre adresse e-mail.',
            p3: "Nous vous invitons également à vérifier votre dossier de spam dans votre boîte de réception, car l'e-mail peut avoir été marqué comme indésirable, en raison de vos préférences configurées.",
            p4: "Nous vous invitons à consulter les ventes aux enchères en ligne et à savoir s'il y a des véhicules qui vous intéressent. Vous souhaitez vendre vos bus lors de la prochaine vente aux enchères ? Contactez-nous à {0}.",
            p5: 'Merci de nous avoir choisis !',
        },
        confirm: {
            success: {
                title: 'Votre adresse e-mail est en cours de vérification !',
                p1: "Merci d'avoir confirmé votre adresse e-mail {email}.",
                p2: 'Votre compte est désormais actif et vous avez un accès complet à toutes nos fonctionnalités.',
                p3: "Si vous avez des questions ou si vous avez besoin d'aide, contactez notre équipe d'assistance à {0}. Bienvenue à bord!",
            },
            error: {
                title: "La vérification de l'adresse e-mail a échoué",
                p1: "We're sorry, but your email verification was unsuccessful. ",
                p2: 'Il semble y avoir eu un problème avec le lien de confirmation. ',
                p3: "Veuillez réessayer en cliquant sur le lien de vérification que nous vous avons envoyé. Si le problème persiste, contactez notre équipe d'assistance à {0}.",
            },
            buyCta: 'Participez aux ventes aux enchères',
            hpCta: "Retour à la page d'accueil",
        },
    },
    termsConditions: {
        link: "Terms {'&'} Conditions",
    },
    login: {
        email: 'Adresse e-mail',
        password: 'Mot de passe',
        formSubtitle: 'Saisissez vos identifiants Fleequid pour participer aux ventes aux enchères',
        forgotPassword: 'Mot de passe oublié ?',
        unregistered: "Don't have a Fleequid account?",
        signupNow: "S'inscrire",
    },
    passwordReset: {
        request: {
            title: 'Réinitialisez votre mot de passe',
            subTitle: 'Saisissez votre adresse e-mail et nous vous enverrons un lien pour réinitialiser votre mot de passe.',
            submitButton: "Envoyer l'e-mail",
            onSuccessTitle: 'Demande acceptée',
            onSuccessDescription: 'Vous recevrez un e-mail avec la procédure à suivre pour finaliser la réinitialisation du mot de passe.',
        },
        reset: {
            title: 'Réinitialisez votre mot de passe',
            description: 'Saisissez un nouveau mot de passe. Une fois le changement confirmé, toutes les sessions actives seront invalidées.',
            newPassword: 'Nouveau mot de passe',
            confirmNewPassword: 'Confirmez le nouveau mot de passe',
            submitButton: 'Modifier le mot de passe',
            updateSuccess: 'Mot de passe modifié avec succès',
            tokenExpired: 'Le jeton de réinitialisation a expiré.',
            tokenInvalid: 'Le jeton de réinitialisation du mot de passe est invalide.',
            startOverDescription: "Recommencez le processus de réinitialisation du mot de passe, de sorte qu'un nouveau jeton soit généré.",
            startOverButton: 'Veuillez réessayer',
        },
    },
    validation: {
        msg: {
            characters: 'Un ou plusieurs caractères invalides',
            email: "Email should resemble user{'@'}domain.ext",
            incomplete: '{field} est incomplet',
            invalid: '{field} invalide',
            invalidBid: 'Le montant inséré est invalide',
            missing: '{field} est manquant',
            max: 'Longueur maximale {count} caractère | Longueur maximale {count} caractères',
            min: 'Longueur minimale {count} caractère | Longueur minimale {count} caractères',
            length: 'La longueur doit être exactement de {count} caractère | La longueur doit être exactement de {count} caractères',
            passwordWeak: 'Mot de passe trop faible',
            passwordMatch: 'Les mots de passe ne correspondent pas',
            phone: "Veuillez n'utiliser que des chiffres, des blancs et le symbole +",
            recaptcha: 'Erreur de validation reCAPTCHA',
            required: '{field} est obligatoire',
        },
        hint: {
            password: {
                text: 'Règles pour un mot de passe fort',
                rules: {
                    length: 'Au moins 8 caractères',
                    uppercase: '1 minuscule',
                    lowercase: '1 majuscule',
                    symbol: '1 symbole',
                    number: '1 chiffre',
                },
            },
        },
    },
    profile: {
        title: 'Votre profil',
        companyData: {
            readOnlyDisclaimer: "Les données de l'entreprise sont en lecture seule. Pour toute modification, contactez-nous à {0}.",
        },
        userData: {
            saveChanges: 'Confirmez les nouvelles données',
            cancelChanges: 'Annuler les modifications',
            updateSuccess: 'Données à caractère personnel enregistrées correctement',
        },
        changePassword: {
            title: 'Modifier le mot de passe',
            description: "La modification de votre mot de passe invalidera toutes les autres sessions actives, à l'exception de la session en cours.",
            currentPassword: 'Mot de passe actuel',
            newPassword: 'Nouveau mot de passe',
            confirmNewPassword: 'Confirmez le nouveau mot de passe',
            submitButton: 'Modifier le mot de passe',
            updateSuccess: 'Mot de passe modifié avec succès',
        },
        changeEmail: {
            title: "Modifier l'adresse e-mail",
            description: 'Vous recevrez un e-mail avec un lien pour confirmer la nouvelle adresse e-mail. La modification de votre adresse e-mail invalidera toutes vos sessions actives.',
            password: 'Mot de passe actuel',
            email: 'Adresse e-mail',
            submitButton: "Modifier l'adresse e-mail",
            updateSuccess: "L'adresse e-mail a été modifiée avec succès",
        },
    },
    sessionExpired: {
        title: 'Votre session a expiré',
        description: 'La session a été invalidée car les identifiants ont été modifiés. Veuillez vous connecter avec les nouveaux identifiants.',
        close: 'Continuer',
    },
    nuxtSiteConfig: {
        description: 'Achetez ou vendez des bus et des véhicules commerciaux aux enchères : rejoignez Fleequid et participez facilement à des enchères B2B de premier choix pour les véhicules industriels',
    },
    negotiation: {
        underNegotiation: 'En négociation',
        numberOfBids: '{count} offre totale | {count} offres totales',
        yourRequest: 'Votre demande',
        sellerRequest: 'Demande du vendeur',
        yourOffer: 'Votre offre',
        buyerOffer: 'Offre de l’acheteur',
        youDeclineOfferNotice: 'Vous avez refusé l’offre',
        sellerDeclineOfferNotice: 'Le vendeur a refusé l’offre',
        expiredNotice: 'Le délai de négociation est expiré sans parvenir à un accord.',
        openNoticeForSeller: 'Le prix de réserve n’a pas été atteint. Il vous reste {countdown} pour négocier avec l’acheteur ({moreInfoLink}).',
        openNoticeForBuyer: 'Le prix de réserve n’a pas été atteint. Il vous reste {countdown} pour négocier avec le vendeur ({moreInfoLink}).',
        underSellerReviewNoticeForSeller: 'L’acheteur a sélectionné cette offre comme {finalOffer}. Il vous reste {countdown} pour l’accepter ou la refuser ({moreInfoLink}).',
        underSellerReviewNoticeFinalOfferForSeller: 'le montant maximum qu’il est disposé à offrir',
        underSellerReviewNoticeForBuyer: 'Vous avez sélectionné cette offre comme {finalOffer}. Le vendeur dispose encore de {countdown} pour l’accepter ou la refuser ({moreInfoLink}).',
        underSellerReviewNoticeFinalOfferForBuyer: 'le montant maximum que vous êtes disposé à offrir',
        moreInfoLink: 'plus d’infos',
        updateRequest: 'Modifier votre demande',
        updateOffer: 'Modifier votre offre',
        acceptRequest: 'Accepter la demande',
        acceptOffer: 'Accepter l’offre',
        buyerStopProposals: 'Bloquer l’offre actuelle',
        sellerDecline: 'Mettre fin à la négociation',
        cancel: 'Annuler',
        proposalModal: {
            title: 'Négocier le prix',
            subtitle: 'Vous disposez de {countdown} pour négocier l’offre.',
            resetProposal: 'réinitialiser',
            stopProposalsLabel: 'Montant maximum que je suis prêt à offrir',
            stopProposalsDescription: 'Si vous sélectionnez cette option, le vendeur ne pourra plus négocier, mais seulement accepter ou refuser cette offre.',
        },
        acceptModal: {
            titleForSeller: 'Acceptez-vous l’offre ?',
            titleForBuyer: 'Acceptez-vous la demande ?',
        },
        sellerDeclineModal: {
            title: 'Mettre fin à la négociation',
            disclaimer: 'En procédant ainsi, vous clôturez l’enchère et perdez les offres reçues. Notre équipe vous recontactera bientôt pour reprogrammer l’enchère.',
            confirmButton: 'Mettre fin à la négociation',
        },
        buyerStopProposalsModal: {
            title: 'Bloquer l’offre actuelle',
            disclaimer: 'En procédant ainsi, le vendeur ne pourra plus négocier, mais seulement accepter ou refuser cette offre.',
            confirmButton: 'Bloquer l’offre actuelle',
        },
        backToNegotiation: 'Négocier maintenant',
        notifications: {
            proposalByBuyer: {
                title: 'Offre modifiée !',
                description: 'Vous avez modifié votre offre à {amount} pour la négociation.',
            },
            proposalBySeller: {
                title: 'Demande modifiée !',
                description: 'Vous avez modifié votre demande à {amount} pour la négociation.',
            },
            acceptByBuyer: {
                title: 'Demande acceptée !',
                description: 'Vous avez accepté la demande du vendeur de {amount}. Négociation conclue avec succès.',
            },
            acceptBySeller: {
                title: 'Offre acceptée !',
                description: 'Vous avez accepté l’offre de l’acheteur de {amount}. Négociation conclue avec succès.',
            },
            declinedBySeller: {
                title: 'Négociation terminée !',
                description: 'Vous avez définitivement refusé l’offre de l’acheteur de {amount}. Négociation close sans succès.',
            },
            stopProposalsByBuyer: {
                title: 'Offre finale confirmée !',
                description: 'Votre offre finale pour la négociation est de {amount}. Veuillez attendre la décision finale du vendeur.',
            },
            apiError: {
                title: 'Une erreur imprévue s’est produite.',
                description: 'Impossible d’effectuer l’opération demandée. Actualisez la page et réessayez.',
            },
        },
    },
};

export default fr;
