import en from './en';

import { AssetDoors, AssetEuroNorm, AssetFuel, AssetGearboxType, AssetPartPosition, AssetSeatUpholstery, AssetStateOfUse, AssetTachograph, AssetType, AssetUseType, AssetWheelchairRamp, AuctionState, BackendErrorCode, BusClass, CruiseControlType, DeckType, GasTanksMaterials, Glasses, RequireScrappingFilter, UserType, VehicleCondition } from '~/apiClient';

type i18nKeys = keyof typeof en;
type i18nValues = (typeof en)[i18nKeys];

const it: Record<i18nKeys, i18nValues> = {
    account: {
        bids: {
            title: 'Le tue offerte',
            emptyState: {
                base: "Non hai ancora piazzato un'offerta.",
                cta: 'Partecipa alle aste',
            },
            filters: {
                bidPosition: {
                    base: 'Aggiudicazione',
                    anyOption: 'Tutte le offerte',
                    maxBidder: 'La tua offerta è la migliore',
                    outbid: 'Offerta superata',
                },
                state: {
                    base: "Stato dell'asta",
                    anyOption: 'Tutte le aste',
                    [AuctionState.Expired]: 'Aste concluse',
                    [AuctionState.Running]: 'Aste in corso',
                },
            },
        },
        sales: {
            title: 'Le tue vendite',
            emptyStateText: 'Non risultano aste con venditore associato a questo account.',
        },
    },
    common: {
        logout: 'Esci',
        login: 'Accedi',
        loginSignup: 'Accedi/Registrati',
        loginTitle: 'Accedi a Fleequid',
        profile: 'Il tuo profilo',
        searchPlaceholder: 'Cerca...',
        searchNoResults: 'Nessun risultato trovato per"{query}"',
        email: 'Email',
        yourAccount: 'Il tuo account',
        language: 'Lingua',
        showMore: 'Mostra di più',
        showLess: 'Mostra di meno',
    },
    cookie: {
        name: 'Nome',
        provider: 'Fornitore',
        purpose: 'Scopo',
        expiry: 'Scadenza',
        type: 'Tipo',
    },
    error: {
        backend: {
            [BackendErrorCode.InvalidEnumValue]: 'Tipo di dato invalido',
            [BackendErrorCode.InvalidDate]: 'Formato data invalido',
            [BackendErrorCode.InvalidLength]: 'Lunghezza errata. Assicurati di aver inserito solo i caratteri necessari',
            [BackendErrorCode.NotAString]: 'Tipo invalido; inserisci un testo',
            [BackendErrorCode.EmptyString]: 'Dato mancante. Completa tutti le informazioni obbligatorie',
            [BackendErrorCode.PrismaError]: 'Impossibile salvare il dato; prova un valore diverso',
            [BackendErrorCode.AuthAuthenticationVersionMismatch]: 'La tua sessione è scaduta. Ti invitiamo a effettuare nuovamente il login per continuare',
            [BackendErrorCode.AuthWrongCredentials]: 'Credenziali non valide',
            [BackendErrorCode.AuthNoUserByEmail]: "Non esiste nessun account associato all'indirizzo email inserito.",
            [BackendErrorCode.AuthWrongPassword]: 'La password inserita non è corretta.',
            [BackendErrorCode.UserIsBanned]: 'La tua utenza è stata sospesa. Contattaci per ulteriori informazioni.',
            [BackendErrorCode.AuthEmailNotVerified]: "L'indirizzo email inserito non risulta ancora verificato. Abbiamo inviato un nuovo link per la verifica dell'email.",
            [BackendErrorCode.SignupUserAlreadyExists]: 'Indirizzo email già registrato, se hai dimenticato la password prova a recuperarla.',
            [BackendErrorCode.UploadFileError]: 'Errore durante il caricamento del file',
            [BackendErrorCode.IsNotTrue]: "Selezione mancante. Spunta l'opzione obbligatoria",
            [BackendErrorCode.EntityNotFound]: 'Nessun risultato trovato',
            [BackendErrorCode.PasswordResetEmailNotExists]: 'Non esiste nessun account associato a questa email',
            [BackendErrorCode.EmailChangeWrongPassword]: "Non puoi modificare l'indirizzo email perché la password non è corretta",
            [BackendErrorCode.CantBidUserCompanyEqSellerCompany]: 'Questo asset è di proprietà della tua azienda, non puoi acquistarlo.',
            [BackendErrorCode.AuctionUpdateDenied]: 'Solo gli utenti delle aziende venditrici sono autorizzati a eseguire questa azione.',
            authentication: 'Errore di autenticazione; riprova il login',
        },
        '404': {
            title: '404',
            subtitle: 'Ops! Pagina non trovata',
            description: 'Siamo spiacenti, la pagina richiesta non esiste. Ad ogni modo troverai molto altro da esplorare su Fleequid.',
            button: 'Torna alla homepage',
        },
        '500': {
            title: '500',
            subtitle: 'Ops! Si è verificato un errore',
            description: "Ci spiace per l'inconveniente. Stiamo già lavorando per risolvere il problema.",
            help: {
                base: 'Nel frattempo puoi riprovare più tardi o {contactUs}',
                contactUs: 'contattarci a {email}, oppure {phone} per emergenze',
            },
            button: 'Torna alla homepage',
        },
        default: {
            title: 'Errore',
            subtitle: 'Si è verificato un errore imprevisto',
            description: 'Stiamo già lavorando per risolvere il problema.',
            button: 'Torna alla homepage',
        },
    },
    a11y: {
        auctionDetail: 'Scopri di più su {name}',
    },
    adp: {
        imageGallery: 'Galleria completa ({totalImages})',
        videoGallery: 'Guarda video ({totalVideos})',
        imagePagination: 'Foto {0} di {1}',
        videoPagination: 'Video {0} di {1}',
        papers: 'Documenti',
        registrationDocument: 'Libretto di circolazione',
        cocDocument: 'Certificato di Conformità (COC)',
        cocDocumentAbsent: 'Il certificato di conformità non è disponibile.',
        maintenanceCard: 'Storico manutenzioni',
        detailsTitle: 'Dettagli del veicolo | Dettagli dei veicoli',
    },
    asset: {
        type: {
            [AssetType.Bus]: 'Autobus',
        },
        class: {
            base: 'Classe',
            [BusClass.ClassOne]: 'Urbano',
            [BusClass.ClassTwo]: 'Interurbano',
            [BusClass.ClassThree]: 'Turistico',
            [BusClass.ClassA]: 'Classe A',
            [BusClass.ClassB]: 'Classe B',
            [BusClass.SchoolBus]: 'Scuolabus',
        },
        kilometers: 'Chilometraggio',
        firstRegistration: 'Immatricolazione',
        antiPollution: 'Normativa antinquinamento',
        euroNorm: {
            [AssetEuroNorm.Euro0]: 'Euro 0',
            [AssetEuroNorm.Euro1]: 'Euro 1',
            [AssetEuroNorm.Euro2]: 'Euro 2',
            [AssetEuroNorm.Euro3]: 'Euro 3',
            [AssetEuroNorm.Euro4]: 'Euro 4',
            [AssetEuroNorm.Euro5]: 'Euro 5',
            [AssetEuroNorm.Euro6]: 'Euro 6',
        },
        toBeScrapped: 'Da demolire',
        expiredInspection: 'Revisione scaduta',
        bundle: 'Lotto',
        vehicleSpecs: 'Specifiche del veicolo',
        bundleSpecs: 'Specifiche del lotto',
        vehiclesInBundle: 'Veicoli compresi nel lotto',
        vehicleIndex: 'Veicolo #{index}',
        vehicleCount: '{count} veicoli',
        description: 'Descrizione',
        version: 'Versione',
        geographicalLocation: 'Località',
        vehicleCondition: {
            base: 'Condizione del veicolo',
            [VehicleCondition.Blocked]: 'Non marciante',
            [VehicleCondition.Rollable]: 'Trainabile',
            [VehicleCondition.Running]: 'Marciante',
        },
        registrationDocumentCountry: 'nazionalità documenti',
        conditionsAndMaintenance: 'Condizioni e Manutenzione',
        stateOfUse: {
            present: 'Presente',
            missing: 'Mancante',
            [AssetStateOfUse.Insufficient]: 'Insufficiente',
            [AssetStateOfUse.WorkingAndMaintained]: 'Funzionante e regolarmente manutenuto',
            [AssetStateOfUse.LikeNew]: 'Come nuovo',
        },
        stateOfUseGearbox: "Stato d'uso cambio",
        stateOfUseAirConditioner: "Stato d'uso condizionatore",
        stateOfUseBreakingSystem: "Stato d'uso circuito frenante",
        stateOfUseDrivingOrgans: "Stato d'uso organi di guida",
        stateOfUseEngine: "Stato d'uso motore",
        stateOfUsePowerSystem: "Stato d'uso circuito di alimentazione",
        stateOfUseTimingBelt: "Stato d'uso cinghia di distribuzione",
        stateOfUseTransmissionDevices: "Stato d'uso turbina",
        stateOfUseTurbine: "Stato d'uso turbina",
        missingParts: 'Parti mancanti',
        useType: {
            base: "Tipo d'uso",
            [AssetUseType.OwnUse]: 'Proprio',
            [AssetUseType.ThirdPartRent]: 'Noleggio terzi',
            [AssetUseType.Line]: 'Linea',
        },
        enginePosition: 'Posizione motore',
        position: {
            [AssetPartPosition.Rear]: 'Posteriore',
            [AssetPartPosition.Central]: 'Centrale',
            [AssetPartPosition.Frontal]: 'Frontale',
        },
        length: 'Lunghezza',
        height: 'Altezza',
        width: 'Larghezza',
        vin: 'Telaio',
        converter: 'Allestitore',
        visitDate: {
            base: 'Visionabile',
            fromTo: 'il {date} dalle {fromTime} alle {toTime}',
        },
        inspectionExpiryDate: 'Data scadenza revisione',
        doors: {
            base: 'Porte',
            [AssetDoors.Double]: '2',
            [AssetDoors.DoubleDouble]: '2+2',
            [AssetDoors.DoubleDoubleDouble]: '2+2+2',
            [AssetDoors.DoubleDoubleSingle]: '2+2+1',
            [AssetDoors.DoubleSingleSingle]: '2+1+1',
            [AssetDoors.Single]: '1',
            [AssetDoors.SingleDouble]: '1+2',
            [AssetDoors.SingleSingle]: '1+1',
            [AssetDoors.SingleDoubleSingle]: '1+2+1',
            [AssetDoors.SingleSingleSingle]: '1+1+1',
            [AssetDoors.SingleDoubleDouble]: '1+2+2',
        },
        seatUpholstery: {
            base: 'Rivestimento sedili',
            [AssetSeatUpholstery.Fabric]: 'Tessuto',
            [AssetSeatUpholstery.Leather]: 'Pelle',
            [AssetSeatUpholstery.Plastic]: 'Plastica',
            [AssetSeatUpholstery.FabricLeather]: 'Pelle/tessuto',
        },
        totalCapacity: 'Numero totale posti',
        seats: 'Posti a sedere',
        seatsFromS1: 'Posti a sedere da libretto conducente compreso (S1)',
        standingPlaces: 'Posti in piedi',
        standingPlacesFromS2: 'Posti in piedi da libretto (S2)',
        foldingSeats: 'Strapuntini',
        wheelchairPlaces: 'Posti carrozzina',
        companionSeats: 'Posti per accompagnatori',
        wheelchairRamp: {
            base: 'Tipologia rampa disabile',
            [AssetWheelchairRamp.Absent]: 'Assente',
            [AssetWheelchairRamp.Electric]: 'Elettrica',
            [AssetWheelchairRamp.Manual]: 'Manuale',
            [AssetWheelchairRamp.Electropneumatic]: 'Elettro-pneumatica',
        },
        luggageCapacity: 'Capacità bagagliera',
        passThroughLuggageCompartment: 'Bagagliera passante',
        hatBox: 'Cappelliera',
        transmission: 'Cambio',
        gearboxType: {
            base: 'Tipologia cambio',
            [AssetGearboxType.Auto]: 'Automatico',
            [AssetGearboxType.Manual]: 'Manuale',
            [AssetGearboxType.SemiAuto]: 'Semiautomatico',
        },
        gearboxBrand: 'Marca cambio',
        gearboxModel: 'Modello cambio',
        glasses: {
            base: 'Vetri',
            [Glasses.Single]: 'Singoli',
            [Glasses.Double]: 'Doppi',
        },
        openableWindows: 'Finestrini apribili',
        engine: 'Motore',
        engineBrand: 'Marca motore',
        engineModel: 'Modello motore',
        hp: 'Cavalli',
        kw: 'Potenza',
        displacement: 'Cilindrata',
        cylinders: 'Numero cilindri',
        fuel: {
            base: 'Alimentazione',
            [AssetFuel.CNG]: 'CNG',
            [AssetFuel.Electric]: 'Elettrico',
            [AssetFuel.GPL]: 'GPL',
            [AssetFuel.Diesel]: 'Diesel',
            [AssetFuel.Gasoline]: 'Benzina',
            [AssetFuel.Hybrid]: 'Ibrido',
            [AssetFuel.Hydrogen]: 'Idrogeno',
        },
        fuelTankCapacity: 'Capacità serbatoio',
        gasTanks: 'Serbatoi gas',
        gasTanksMaterial: {
            base: 'Materiale serbatoio',
            [GasTanksMaterials.Aluminum]: 'Alluminio',
            [GasTanksMaterials.Composite]: 'Composito',
            [GasTanksMaterials.Steel]: 'Acciaio',
        },
        batteryPower: 'Capacità della batteria',
        chargingType: 'Tipo ricarica',
        axlesTires: 'Assali e pneumatici',
        wheelSize: 'Dimensione pneumatici',
        wheels: 'Numero pneumatici (doppi o singoli)',
        axles: 'Assi',
        wheelsSizeAxle1: 'Dimensione ruote asse 1',
        wheelsSizeAxle2: 'Dimensione ruote asse 2',
        wheelsSizeAxle3: 'Dimensione ruote asse 3',
        alloyWheels: 'Cerchi in lega',
        spareWheel: 'Ruota di scorta',
        firePreventionSystem: 'Sistema antincendio',
        firePreventionSystemBrand: 'Marca sistema antincendio',
        abs: 'ABS',
        asr: 'ASR',
        retarder: 'Rallentatore',
        breathalyzer: 'Etilometro',
        tachograph: { base: 'Tachigrafo', [AssetTachograph.Absent]: 'Assente', [AssetTachograph.Analog]: 'Analogico', [AssetTachograph.Digital]: 'Digitale' },
        indicatorSigns: 'Pannelli indicatori',
        indicatorSignsBrand: 'Produttore pannelli indicatori',
        accessories: 'Accessori',
        cruiseControl: {
            base: 'Cruise control',
            [CruiseControlType.No]: 'No',
            [CruiseControlType.Adaptive]: 'Adaptive',
            [CruiseControlType.Standard]: 'Standard',
        },
        deckType: {
            base: 'Pianale',
            [DeckType.LowEntry]: 'Low Entry',
            [DeckType.LowFloor]: 'Low Floor',
            [DeckType.NormalFloor]: 'Normal Floor',
        },
        acDriver: 'Aria condizionata autista',
        driverBerth: 'Cuccetta conducente',
        wifi: 'Wi-Fi',
        cctv: 'Videosorveglianza (CCTV)',
        internalCameras: 'Telecamere interne',
        externalCameras: 'Telecamere esterne',
        retrocamera: 'Retrocamera',
        microphone: 'Microfono',
        cdPlayer: 'CD player',
        dvdPlayer: 'DVD player',
        usbPlayer: 'Radio USB player',
        heater: 'Riscaldamento',
        preHeater: 'Preriscaldatore',
        kitchen: 'Cucina',
        coffeeMachine: 'Macchina del caffè',
        refrigerator: 'Frigorifero',
        toilet: 'Toilet',
        safetyBelts: 'Cinture di sicurezza',
        acPassengers: 'Aria condizionata passeggero',
        curtains: 'Tendine parasole',
        seatPocket: 'Retina portaoggetti',
        footRest: 'Poggiapiedi',
        handRest: 'Braccioli',
        mp3: 'MP3',
        monitors: 'Monitor',
        seatsMonitor: 'Monitor ai sedili',
        seatsRadio: 'Radio ai sedili',
        seatsUsbPorts: 'USB ai sedili',
        socket220: 'Presa di corrente autista 220V',
        seatsSocket220: 'Prese di corrente ai sedili 220V',
        extendableSeats: 'Sedili allargabili',
        recliningSeats: 'Sedili reclinabili',
        coffeeTables: 'Tavolini ai sedili',
    },
    auction: {
        countdown: {
            startsIn: 'Inizia tra',
            endsIn: 'Termina tra',
            days: 'd',
            hours: 'h',
            minutes: 'm',
            seconds: 's',
        },
        inspectionExpiredModal: {
            title: 'Revisione richiesta per l’acquisto fuori dall’Italia',
            description: 'Questo autobus si trova in Italia e, secondo il Codice della Strada vigente, i mezzi con revisione scaduta non sono esportabili al di fuori del Paese. Potrai partecipare e aggiudicarti l’asta, ma per ottenere il mezzo dovrai:',
            item1: 'Effettuare la revisione prima dell’esportazione',
            item2: 'Sostenere gli eventuali costi previsti',
            item3: 'Completare la procedura di radiazione per esportazione definitiva',
            askConfirm: 'Vuoi comunque procedere a vincolare l’importo minimo?',
            confirmButton: 'Conferma e procedi',
        },
        bail: {
            title: 'Vincola il deposito cauzionale per partecipare all’asta',
            description: 'Per poter partecipare a quest’asta, dovrai vincolare il deposito cauzionale tramite un metodo di pagamento valido.',
            amount: 'DEPOSITO CAUZIONALE',
            note: "Il deposito cauzionale verrà rilasciato alla fine dell'asta se non risulti tra i migliori offerenti.",
            subnote: "I depositi cauzionali non sono uguali per tutti gli asset e possono variare a seconda della base d'asta",
            error: "Si è verificato un errore durante l'elaborazione della transazione, riprova più tardi o contatta l'assistenza",
            submit: 'Vincola il deposito cauzionale',
        },
        bid: {
            title: 'Diventa subito il miglior offerente',
            description: 'Ricorda che l’offerta singola o massima è vincolante.',
            autoBidEditTitle: 'Modifica il tuo rilancio automatico',
            autoBidEditDescription: 'Ricorda che l’offerta massima è vincolante.',
            activeAutoBid: 'Rilancio automatico attivo fino a',
            actualBid: 'Offerta attuale',
            autoBidToggle: 'Rilancio automatico ({0})',
            autoBidLink: 'cos’è?',
            yourBid: 'La tua offerta',
            yourMaxBid: 'La tua offerta massima',
            restoreMinBid: 'Reimposta',
            confirmSingleBid: 'Fai la tua offerta',
            confirmAutoBid: 'Rilancia automaticamente',
            confirmAutoBidUpdate: 'Modifica rilancio automatico',
            cancel: 'Cancella',
            success: 'Offerta accettata!',
            outbid: 'Un altro utente ha superato la tua offerta',
            expiredError: {
                title: "L'asta è terminata",
                description: 'Non è più possibile effettuare altre offerte.',
            },
            bidError: {
                title: 'Si è verificato un errore imprevisto.',
                description: 'Non è stato possibile inserire la tua offerta. Aggiorna la pagina e prova nuovamente ad inserire un’offerta.',
            },
        },
        bidButton: 'Fai un’offerta',
        bidButtonActiveAutoBid: 'Modifica rilancio automatico',
        auctionPanel: {
            reservePrice: 'Prezzo di riserva',
            startingPrice: 'Offerta di partenza',
            current: 'Attuale',
            wonAuction: 'Asta aggiudicata!',
            state: {
                [AuctionState.Draft]: 'Asta in bozza',
                [AuctionState.ToBeApproved]: 'Asta da approvare',
                [AuctionState.ToBeScheduled]: 'In attesa di programmazione',
                [AuctionState.Waiting]: 'In attesa di pubblicazione',
                [AuctionState.Expired]: 'Asta terminata',
                [AuctionState.Canceled]: 'Asta annullata',
            },
            maxBidder: 'La tua offerta è la migliore!',
            outBid: 'Offerta superata!',
            negotiationInProgress: 'Trattativa in corso',
            feeNotice: 'commissioni ({0})',
            feeNoticeLink: 'cosa sono?',
            notice: {
                cantParticipate: {
                    [BackendErrorCode.CantBidScrapAuctionNotScrapyardCompany]: "Asta riservata a demolitori iscritti all'albo gestori dei rifiuti in cat. 5 e gestori del codice C.E.R. 16.01.04.",
                    [BackendErrorCode.CantBidUserCompanyEqSellerCompany]: 'Il veicolo è messo in vendita dalla tua azienda.',
                    [BackendErrorCode.CantBidUnapprovedCompany]: 'Non puoi effettuare offerte perché i documenti della tua azienda risultano incompleti o scaduti.',
                    [BackendErrorCode.CantBidCompanyUnderReview]: 'Non puoi effettuare offerte perché stiamo revisionando i documenti della tua azienda.',
                    [BackendErrorCode.CantBidRegisteredCompany]: 'Non puoi effettuare offerte perché stiamo stiamo verificando i dati della tua azienda.',
                    [BackendErrorCode.CantBidUserWithoutCompany]: 'Non puoi effettuare offerte perché non abbiamo trovato nessuna azienda associata al tuo profilo.',
                    [BackendErrorCode.CantBidCompanyNotYetApprovedForScrap]: "Non puoi effettuare offerte perché stiamo verificando la tua iscrizione all'albo gestori dei rifiuti in cat. 5 e gestori del codice C.E.R. 16.01.04.",
                    [BackendErrorCode.CantBidCompanyNotApprovedForScrap]: "Non puoi effettuare offerte perché la tua azienda non risulta iscritta all'albo gestori dei rifiuti in cat. 5 e gestori del codice C.E.R. 16.01.04.",
                    generic: 'Non puoi partecipare a questa asta.',
                },
                reservePriceNotReached: {
                    base: 'Prezzo di riserva {0}',
                    notReached: 'non ancora raggiunto',
                },
                inspectionExpired: 'Per esportare questo veicolo è necessario effettuare la revisione.',
            },
        },
        needSupport: 'Hai bisogno di supporto?',
        contactUs: 'Contatta il servizio clienti Fleequid',
        draftConfirmation: {
            title: 'Sei in modalità bozza',
            description: 'Controlla tutti i dettagli dell’asta e conferma la correttezza dei dati inseriti. ',
            loginAction: 'Accedi per confermare',
            action: 'Conferma l’asta',
            success: {
                title: "Hai confermato l'asta",
                description: 'La conferma dei dati è stata inviata correttamente',
            },
        },
        multi: {
            title: 'Bundle di {count} mezzi',
            detailTitle: 'Specifiche del {name}',
        },
    },
    auctions: {
        page: {
            asset: {
                [AssetType.Bus]: 'autobus',
            },
            title: 'Scopri tutti gli {title} su Fleequid',
            results: '{total} aste visibili',
            emptyStateTitle: 'Nessun risultato',
            emptyStateText: 'I filtri applicati non hanno prodotto nessun risultato.',
            resetFilters: 'Rimuovi filtri',
        },
        filters: {
            applied: 'Filtri applicati',
            button: {
                label: 'Filtra aste',
            },
            modal: {
                title: 'Filtra aste',
                back: 'Torna ai risultati',
            },
            label: 'Filtri',
            class: 'Classe',
            brand: 'Marchio, modello, versione',
            brandFilter: 'Marchio',
            brandFilterAnyOption: 'Tutti i brand',
            modelFilter: 'Modello',
            modelFilterAnyOption: 'Tutti i modelli',
            versionFilter: 'Versione',
            versionFilterAnyOption: 'Tutte le versioni',
            antiPollution: 'Normativa antinquinamento',
            registration: 'Immatricolazione',
            yearFrom: 'A partire da (anno)',
            yearTo: 'Fino a (anno)',
            yearAnyOption: 'Qualsiasi anno',
            kilometers: 'Chilometraggio',
            kmFrom: 'A partire da',
            kmTo: 'Fino a',
            kmAnyOption: 'Qualsiasi chilometraggio',
            state: {
                title: 'Stato asta',
                all: 'Tutti gli stati',
                [AuctionState.Pending]: 'Aste non ancora iniziate',
                [AuctionState.Running]: 'Aste in corso',
                [AuctionState.Expired]: 'Aste terminate',
            },
            scrapping: {
                title: 'Vincoli demolizione',
                help: 'Cosa sono i vincoli di demolizione?',
                all: 'Tutti i veicoli',
                [RequireScrappingFilter.requireScrapping]: 'Con obbligo di demolizione',
                [RequireScrappingFilter.doNotRequireScrapping]: 'Senza obbligo di demolizione',
            },
            fuel: 'Alimentazione',
            length: 'Lunghezza',
            lengthFrom: 'A partire da',
            lengthTo: 'Fino a',
            lengthAnyOption: 'Qualsiasi lunghezza',
            empty: 'Nessun filtro applicato.',
        },
        sorting: {
            title: 'Ordina per',
            yearAsc: 'Immatricolazione meno recente',
            yearDesc: 'Immatricolazione più recente',
            priceAsc: 'Meno costoso',
            priceDesc: 'Più costoso',
            kmAsc: 'Chilometraggio crescente',
            kmDesc: 'Chilometraggio decrescente',
            defaultByState: {
                all: 'Ordine predefinito',
                [AuctionState.Running]: 'Scadenza più imminente',
                [AuctionState.Expired]: 'Termine più recente',
                [AuctionState.Pending]: 'Inizio più imminente',
            },
        },
        appliedFilters: {
            from: 'da: {0}',
            to: 'a: {0}',
            reset: 'Rimuovi filtri',
            appliedFiltersCounter: '{count} filtro attivo | {count} filtri attivi',
        },
    },
    homepage: {
        link: 'Home',
        hero: {
            title: {
                base: '{0} mezzi pesanti',
                highlight: 'Non esistono',
            },
            subtitle: 'Compra il bus che cerchi in soli 7 giorni, il prezzo lo decidi tu.',
        },
        why: {
            title: 'Perché Fleequid?',
            section: {
                purchase: 'Cerca tra centinaia di bus in tutto il mondo e compra il tuo prossimo mezzo in 7 giorni',
                assistance: 'Ti aiutiamo a scegliere con immagini dettagliate, informazioni complete e supporto tecnico di qualità',
                fairness: "L'autobus è venduto al suo giusto prezzo grazie al meccanismo dell’asta",
            },
        },
        stats: {
            title: 'Fleequid in numeri',
            section: {
                purchase: 'Bus venduti in 3 mesi',
                markets: 'Nazioni in cui operiamo',
                team: 'Membri del team',
                registrations: 'Aziende registrate in 3 mesi',
            },
        },
        howItWorks: {
            title: 'Come funziona l’asta?',
            section: {
                register: {
                    title: '1. Registra la tua azienda',
                    content: 'Inserisci i dati relativi alla tua azienda sulla piattaforma.',
                },
                choose: {
                    title: '2. Scegli il tuo autobus',
                    content: 'Naviga tra le aste in corso e quelle future per trovare il veicolo dei tuoi sogni. Fatti aiutare dai filtri, dalle immagini e dalle accurate specifiche tecniche.',
                },
                bid: {
                    title: '3. Fai la tua offerta',
                    content: 'Quando l’asta è attiva, partecipa anche tu! Segui l’andamento delle offerte e fanne ancora se la tua viene superata. Ricorda: ci serve sapere che la tua offerta è seria, quindi aggiungi una cauzione con carta di credito per validarla.',
                },
                autobid: {
                    title: '4. Prova il rilancio automatico',
                    content: 'Hai paura di perdere l’asta? Fissa un’offerta massima che sei disposto ad offrire, rilanciamo noi per te in automatico in modo da superare le offerte degli altri utenti (rispettando il tuo limite).',
                },
                closure: {
                    title: '5. Fine dell’asta',
                    content: 'Hai vinto? Effettua il pagamento a Fleequid, che agisce come intermediario fidato: gestiamo i pagamenti e coordiniamo tutte le pratiche amministrative durante il passaggio di proprietà del veicolo. Non sei il vincitore? Il tuo deposito verrà rilasciato.',
                },
            },
        },
    },
    block: {
        support: {
            title: 'Dubbi o domande?',
            section: {
                business: { title: 'Informazioni commerciali' },
                press: { title: 'Ufficio stampa' },
                careers: { title: 'Lavora con noi', label: 'Pagina LinkedIn' },
            },
        },
        whoWeAre: {
            title: {
                base: `Abbiamo {0} di esperienza`,
                highlight: '40+ anni',
            },
            description: 'Fleequid nasce dall’esperienza di Basco, azienda leader nel mercato internazionale della compravendita di autobus usati. La velocità, la concretezza e la professionalità di Basco sono stati la scintilla per l’innovazione tecnologica di Fleequid.',
        },
    },
    faq: {
        title: 'Domande frequenti',
        start: {
            base: 'Come iniziare',
            participate: {
                q: "Come si partecipa a un'asta online?",
                a: "Per partecipare alle aste online su Fleequid è necessario registrarsi sul portale, alla pagina {0}, fornendo le informazioni relative alla propria azienda e inviando i documenti richiesti. Una volta fatto questo, è possibile visitare le pagine relative alle aste in corso ed effettuare una o più puntate per competere all’acquisto di un veicolo. Le aste si svolgono telematicamente e le offerte possono essere inserite fino alla chiusura dell'asta. L'asta si conclude con l'aggiudicazione del veicolo all'utente che ha presentato l'offerta più alta, a condizione che questa sia pari o superiore al prezzo di riserva, oppure in seguito all'accettazione di un'offerta inferiore al prezzo di riserva da parte del venditore.",
                link: 'https://www.fleequid.com/it/signup',
            },
            reservePrice: {
                q: "Cos'è il prezzo di riserva?",
                a: "Il prezzo di riserva è l'importo minimo da superare perché l’asta possa concludersi con successo. Se nel corso dell’asta non dovesse essere raggiunto, spetta al venditore del veicolo decidere se è disposto ad accettare comunque la migliore offerta ricevuta. È un importo più alto della base d’asta: viene deciso dal venditore prima della partenza dell'asta e non sarà visibile al pubblico, che conosce solo il valore della puntata massima fatta fino a quel momento.",
            },
            countries: {
                q: 'In quali paesi è possibile comprare e vendere autobus tramite Fleequid?',
                a: 'Fleequid opera in Italia, in Unione Europea e in diversi Paesi Extra UE, attenendosi alle regolamentazioni delle singole nazioni. Se hai dubbi sulla possibilità di utilizzare Fleequid dal tuo Paese contatta il servizio clienti scrivendo a {0}.',
            },
            bureaucracy: {
                q: 'Chi si occupa dei passaggi di proprietà, della nazionalizzazione ed in genere degli aspetti documentaliChi si occupa dei passaggi di proprietà, della nazionalizzazione ed in genere degli aspetti documentali?',
                a: 'Fleequid comunicherà a venditore e compratore come gestire gli aspetti documentali, e i pagamenti relativi al passaggio di proprietà, in base alle specificità della compravendita in atto (tra aziende italiane, UE, extra UE) e in base al ruolo stesso della piattaforma nel processo di compravendita. La registrazione del veicolo nel Paese di destinazione è comunque a carico e a cura dell’acquirente.',
            },
            toBeScrapped: {
                q: 'Cosa sono i veicoli destinati alla demolizione?',
                a: "I veicoli destinati alla demolizione sono quelli che, non essendo più idonei alla circolazione e al trasporto di persone, devono necessariamente, per leggi vigenti o regolamenti di gara, essere sottoposti ad un processo di recupero e riutilizzo, anche parziale, delle sue componenti come pezzi di ricambio. La partecipazione alle aste per questi veicoli è consentita solo a demolitori iscritti all'albo gestori dei rifiuti in cat. 5 e gestori del codice C.E.R. 16.01.04. Attualmente, il servizio di asta di veicoli destinati alla demolizione è disponibile solo sul territorio italiano",
            },
            vies: {
                q: 'Che cos’è il Vies? Perché è obbligatorio esservi inclusi?',
                a: 'L’archivio Vies (VAT information exchange system, sistema per lo scambio di informazioni sull’IVA) è un motore di ricerca (non una banca dati) creato dalla Commissione Europea per facilitare le compravendite tra aziende appartenenti agli Stati membri. Dato che è necessario essere inclusi nell’archivio Vies per poter effettuare operazioni intracomunitarie, le aziende europee che vogliono registrarsi su Fleequid devono necessariamente esservi presenti.',
            },
            coc: {
                q: 'Che cos’è il COC?',
                a: "Il COC, o Certificato di Conformità (in inglese, Certificate of Conformity), è un documento che attesta quali requisiti tecnici e di sicurezza, previsti dalle normative europee, il veicolo soddisfa. Il documento comprende diverse informazioni relative al veicolo, tra cui le specifiche tecniche, il numero di telaio, i dati relativi alle emissioni, la classe di omologazione del veicolo e le norme di sicurezza che il veicolo rispetta. È un documento richiesto per immatricolare un veicolo in un diverso Paese dell'Unione Europea. La disponibilità di questo certificato evita la necessità di ulteriori controlli tecnici. La mancanza del COC in fase di vendita potrebbe, di contro, non solo impedirne l’immatricolazione ma anche esporre il venditore a sanzioni.",
            },
        },
        sell: {
            base: 'Come vendere',
            selling: {
                q: 'Come posso iniziare a vendere su Fleequid?',
                a: 'Se hai un autobus da vendere contatta il nostro servizio clienti scrivendo a {0} oppure compila il form che trovi alla pagina {1} inserendo le informazioni relative al veicolo.  È necessario, inoltre, registrarsi come venditore sulla piattaforma.',
            },
            startingPrice: {
                q: 'Come si definisce la base d’asta?',
                a: "La base d'asta è il prezzo minimo del veicolo al di sopra del quale i potenziali acquirenti possono inserire le loro offerte o effettuare rilanci sul Marketplace. La base d’asta è calcolata da Fleequid sulla base delle sue valutazioni tecniche effettuate sul mezzo ed è necessariamente più bassa del prezzo di riserva, che è invece stabilito dal venditore.",
            },
            delivery: {
                q: 'Come avviene la consegna del mezzo?',
                a: "Dopo aver ricevuto il pagamento, il venditore deve mettere l’acquirente nelle condizioni di ritirare il veicolo; l’acquirente deve procedere celermente al ritiro, che deve avvenire entro dieci giorni dalla data in cui Fleequid comunica all'acquirente il completamento delle pratiche relative alla de-registrazione nel Paese di origine. Per ogni giorno di ritardo successivo alla tempistica sopra indicata, Fleequid applica una penale.",
            },
            howLong: {
                q: 'In quanto tempo l’autobus viene venduto?',
                a: 'Quando un veicolo viene caricato sulla piattaforma vengono comunicati la data di inizio e di fine dell’asta – che sono stabilite da Fleequid in base alle specificità del veicolo da vendere – e i tempi di visibilità pre-asta. Qualora la vendita non dovesse concludersi con successo Fleequid avrebbe comunque il diritto di effettuare fino a 3 aste consecutive in complessivi 60 giorni.',
            },
            expiredInspection: {
                q: 'Posso vendere un veicolo con revisione scaduta?',
                a: "In Italia, per esportare un veicolo all'estero, è necessario che venga cancellato dal Pubblico Registro Automobilistico (PRA). Una delle condizioni per la cancellazione è che il veicolo abbia una revisione in corso di validità. Un veicolo non revisionato ne limita la commerciabilità al solo territorio italiano. Se desideri ottenere più offerte e, di conseguenza, un prezzo di vendita più elevato, è consigliabile che il venditore metta all'asta il veicolo con la revisione valida.",
            },
        },
        buy: {
            base: 'Come comprare',
            inspection: {
                q: 'È possibile ispezionare i veicoli prima di fare offerte?',
                a: 'L’utente venditore può decidere se offrire l’opzione di visita dal vivo al veicolo. Se lo permette, verrà definito un giorno in cui sarà possibile effettuare visite dal vivo e ispezioni del mezzo direttamente presso l’utente venditore.',
            },
            claims: {
                q: 'Come gestite vizi occulti e reclami?',
                a: "Fleequid è un intermediario tra venditore e compratore e, come tale, non è responsabile di eventuali vizi, anche occulti, difetti, usura, irregolarità, inidoneità all'uso, come di errori o imprecisioni delle dichiarazioni rese dal venditore. Pubblicando il suo annuncio su Fleequid il venditore si impegna alla trasparenza e a dare indicazioni reali sullo stato del veicolo; allo stesso tempo, il compratore deve impegnarsi a segnalare in tempi brevi eventuali incongruenze (3 giorni per i vizi apparenti) ed entro un mese dal ritiro i vizi occulti del veicolo. Fleequid è a disposizione dell’acquirente per la gestione del reclamo e fornisce condizioni specifiche per la loro gestione.",
            },
            paymentMethods: {
                q: 'Quali metodi di pagamento sono accettati?',
                a: 'Fleequid accetta bonifici bancari e pagamenti con carta di credito. Nel dettaglio, è possibile versare il deposito cauzionale solo con carta di credito, mentre per l’acquisto del veicolo e il pagamento delle commissioni e di eventuali spese accessorie deve avvenire tramite bonifico bancario.',
            },
            howPaymentWorks: {
                q: 'Come funziona il pagamento?',
                a: "L’utente che si è aggiudicato l’asta è tenuto a corrispondere un importo che comprende il prezzo di aggiudicazione, le commissioni di vendita per Fleequid e ogni altra tariffa concordata (confronta le Condizioni Generali per maggiori dettagli). L'acquirente deve pagare quanto dovuto mediante bonifico bancario entro e non oltre tre giorni dalla ricezione della fattura corrispondente. Sono vietati i pagamenti parziali.",
            },
            deposit: {
                q: 'Come funziona il deposito cauzionale?',
                a: "Il deposito cauzionale è la somma di denaro che l'utente versa a Fleequid al momento della formulazione di un'offerta in asta, come garanzia della serietà dell'offerta stessa. Quando l’asta finisce tutti i depositi vengono rilasciati, ad eccezione di quelle del primo e secondo classificato. Se Il vincitore finalizza l’acquisto del mezzo, effettuando il bonifico per la cifra concordata  (entro e non oltre 3 giorni dalla ricezione della fattura corrispondente), vengono rilasciati sia il suo deposito sia quello del secondo. Nel caso in cui il vincitore non pagasse nei tempi concordati, perderebbe sia l’autobus sia il deposito cauzionale. In questo caso il secondo classificato sarà di diritto il nuovo vincitore dell'asta e, acquistando con bonifico il veicolo, si vedrà restituito il deposito cauzionale.",
            },
            autoBid: {
                q: 'Come funziona il rilancio automatico?',
                a1: "Il rilancio automatico ti permette di impostare un'offerta massima che sei disposto ad offrire per l’asta (al netto delle commissioni).",
                a2: "Quando lo attivi, il sistema effettua subito la minore offerta valida, considerando il prezzo di riserva e le offerte correnti degli altri utenti. Successivamente, se altri utenti fanno offerte, il sistema rilancerà automaticamente per conto tuo, incrementando l'offerta fino all'importo massimo da te definito, raggiunto il quale, il rilancio automatico si disattiva.",
                a3: "Il rilancio automatico cerca anche di raggiungere il prezzo di riserva, per massimizzare le tue probabilità di aggiudicarti l'asta.",
                a4: "In caso più utenti attivino il rilancio automatico con lo stesso importo massimo, verrà piazzata un'offerta per ogni utente dell'importo massimo, ma sarà considerata vincente solo quella dell'utente che lo ha attivato per primo. Ricorda che tutte le offerte piazzate sono comunque vincolanti, anche se perdenti.",
                a5: "Tieni presente che non puoi annullare un rilancio automatico finché è attivo, ma puoi solo aumentarne l'importo massimo.",
            },
            negotiation: {
                q: 'Come funziona la negoziazione ad asta chiusa?',
                a1: 'La negoziazione ad asta chiusa permette di raggiungere un accordo tra acquirente e venditore quando un’asta si chiude senza che la massima offerta abbia raggiunto il prezzo di riserva. Questo processo mira ad incentivare un accordo tra le parti, aumentando così le possibilità di vendita.',
                a2: 'La negoziazione viene automaticamente avviata alla chiusura dell’asta senza superamento del prezzo di riserva e ha una durata di massimo 72 ore.',
                a3: 'Sia acquirente che venditore, a negoziazione iniziata, vedranno le proposte di entrambe le parti, che si chiamano rispettivamente offerta e richiesta:',
                a3_1: 'Per offerta si intende il prezzo (al netto delle {0}) che l’acquirente è disposto a pagare per aggiudicarsi la negoziazione. L’offerta iniziale dell’acquirente per il venditore è l’offerta massima che è stata inserita durante l’asta.',
                a3_2: 'Per richiesta si intende il prezzo (al netto delle {0}) più basso che il venditore è disposto ad accettare per concludere la negoziazione. La richiesta iniziale del venditore per l’acquirente è il prezzo di riserva dell’asta.',
                a4: 'Durante la negoziazione, in qualsiasi momento, sia il venditore che l’acquirente possono:',
                a4_1: 'Modificare la propria proposta, il venditore abbassando la richiesta e l’acquirente incrementando l’offerta.',
                a4_2: 'Accettare la proposta dell’altra parte.',
                a5: 'Inoltre in ogni istante:',
                a5_1: 'L’acquirente può bloccare la propria offerta corrente. Questa azione è irreversibile e, successivamente, il venditore avrà la possibilità di accettarla o rifiutarla, concludendo così la negoziazione.',
                a5_2: 'Il venditore può terminare la negoziazione, facendo chiudere l’asta senza aggiudicazione.',
                a6: 'La negoziazione si conclude con successo se una delle parti accetta la proposta dell’altra. Nel caso in cui la negoziazione non dovesse portare ad un’accettazione entro le 72 ore o nel caso in cui il venditore termini la negoziazione, l’asta si riterrà non aggiudicata.',
                fee: 'commissioni',
            },
        },
        contactUs: 'Ci auguriamo che tu abbia trovato in questa pagina tutte le risposte alle tue domande, ma in caso contrario siamo sempre felici di aiutarti. Chiamaci al {0}, oppure contattaci scrivendo a {1}',
    },
    footer: {
        cookiePolicy: {
            base: 'Cookie policy',
            changeConsent: 'Cambia il tuo consenso',
            revokeConsent: 'Revoca il tuo consenso',
        },
        explore: 'Naviga',
        legal: 'Informazioni legali',
        support: 'Hai bisogno di supporto?',
        vat: 'P.IVA',
        backTop: 'Torna in alto',
    },
    menu: {
        buy: 'Compra',
        sell: 'Vendi',
        faq: 'F.A.Q.',
    },
    mktg: {
        whyFleequid: {
            title: 'Perché Fleequid?',
            deal: {
                title: 'Compravendite semplificate',
                description: 'Una piattaforma tecnologica al posto di trattative con interlocutori indecisi',
            },
            processes: {
                title: 'Processi veloci',
                description: 'Fleequid riduce i tempi e fa risparmiare energie al tuo staff',
            },
            support: {
                title: 'Un supporto trasparente',
                description: 'Tutto è chiaro, dalla registrazione alle fasi di consegna del mezzo',
            },
        },
    },
    notifications: {
        goToAuction: "Vai all'asta",
        success: 'Successo',
        warning: 'Attenzione',
        error: 'Errore',
        genericError: 'Si è verificato un errore. Riprova più tardi.',
        tryAgain: 'Si prega di riprovare',
        userOutBid: {
            title: 'La tua offerta è stata superata!',
            description: 'La tua offerta di {prevBidAmount} per {subject} è stata superata, il nuovo prezzo corrente è {currentBidAmount}',
            titleAutoBidTie: 'La tua offerta è stata battuta!',
            descriptionAutoBidTie: "La tua offerta di {prevBidAmount} per {subject} è stata battuta perché un altro utente aveva precedentemente inserito un rilancio automatico con un'offerta uguale alla tua.",
            titleAutoBid: 'La tua offerta è stata superata!',
            descriptionAutoBid: "La tua offerta di {prevBidAmount} per {subject} è stata superata perché un altro utente aveva precedentemente inserito un rilancio automatico con un'offerta superiore alla tua. Il nuovo prezzo corrente è {currentBidAmount}.",
            inAuction: 'questa asta',
            linkedAuction: "l'asta di {brand} {model} {version}",
        },
        userBailAuthorized: {
            title: "Sei autorizzato a partecipare all'asta!",
            description: 'Sei stato autorizzato a partecipare {subject}',
            inAuction: 'a quest’asta',
            linkedAuction: 'all’asta di {brand} {model} {version}',
        },
        userMaxBidderRestored: {
            title: 'Sei il miglior offerente!',
            description: 'La tua offerta di {prevBidAmount} per {subject} è la migliore!',
            inAuction: 'questa asta',
            linkedAuction: "l'asta di {brand} {model} {version}",
        },
        auctionWon: {
            title: 'Asta conclusa - Hai vinto!',
            description: 'Siamo davvero contenti di informarti che ti sei aggiudicato {subject} grazie alla tua offerta di {currentPrice}. {reserve}',
            inAuction: 'questa asta',
            linkedAuction: "l'asta di {brand} {model} {version}",
            reservePriceNotReached: 'Purtroppo il prezzo minimo non è stato raggiunto, contatteremo il venditore per proporre la tua offerta e vedere se è disposto a procedere con la vendita. Ti terremo aggiornato su eventuali sviluppi.',
        },
        userWinningBid: {
            title: 'La tua offerta è la migliore!',
            description: 'La tua offerta di {amount} per {subject} è stata piazzata e sei il miglior offerente',
            inAuction: 'questa asta',
            linkedAuction: "l'asta di {brand} {model} {version}",
        },
        userAutoBidMaxThresholdReached: {
            title: 'Il tuo rilancio automatico ha raggiunto il limite!',
            description: 'Il tuo rilancio automatico di {amount} per {subject} ha raggiunto il limite massimo e ora non è più attivo.',
            inAuction: 'questa asta',
            linkedAuction: "l'asta di {brand} {model} {version}",
        },
        negotiationStarted: {
            title: 'Negoziazione iniziata!',
            description: "E' iniziata la negoziazione per {subject}.",
            inAuction: 'questa asta',
            linkedAuction: "l'asta di {brand} {model} {version}",
        },
        negotiationExpired: {
            title: 'Negoziazione scaduta!',
            description: 'Il tempo per la {subject} è scaduto. Negoziazione conclusa senza successo.',
            inAuction: 'negoziazione',
            linkedAuction: 'negoziazione di {brand} {model} {version}',
        },
        negotiationProposalByBuyer: {
            title: 'Offerta modificata!',
            description: "L'acquirente ha modificato la sua offerta a {proposalAmount} per la {subject}.",
            inAuction: 'negoziazione',
            linkedAuction: 'negoziazione di {brand} {model} {version}',
        },
        negotiationProposalBySeller: {
            title: 'Richiesta modificata!',
            description: 'Il venditore ha modificato la sua richiesta a {proposalAmount} per la {subject}.',
            inAuction: 'negoziazione',
            linkedAuction: 'negoziazione di {brand} {model} {version}',
        },
        negotiationAcceptedByBuyer: {
            title: 'Richiesta accettata!',
            description: "L'acquirente ha accettato la tua richiesta di {acceptedAmount} per la {subject}. Negoziazione conclusa con successo.",
            inAuction: 'negoziazione',
            linkedAuction: 'negoziazione di {brand} {model} {version}',
        },
        negotiationAcceptedBySeller: {
            title: 'Offerta accettata!',
            description: 'Il venditore ha accettato la tua offerta di {acceptedAmount} per la {subject}. Negoziazione conclusa con successo.',
            inAuction: 'negoziazione',
            linkedAuction: 'negoziazione di {brand} {model} {version}',
        },
        negotiationsDeclinedBySeller: {
            title: 'Negoziazione terminata!',
            description: 'Il venditore ha rifiutato in maniera definitiva la tua offerta di {declinedAmount} per la {subject}. Negoziazione conclusa senza successo.',
            inAuction: 'negoziazione',
            linkedAuction: 'negoziazione di {brand} {model} {version}',
        },
        negotiationStoppedProposalsByBuyer: {
            title: 'Ultima offerta!',
            description: "L'acquirente ha dichiarato che la sua ultima offerta è di {maxBuyerProposal} per la {subject}. Spetta a te la decisione finale.",
            inAuction: 'negoziazione',
            linkedAuction: 'negoziazione di {brand} {model} {version}',
        },
    },
    privacy: {
        link: 'Informativa Privacy',
    },
    seo: {
        auction: {
            title: '{asset} {brand} {model} {version} {vin}',
            description: 'Acquista {brand} {model} {version}, {asset} usato su Fleequid.com: partecipa all’asta B2B del e fai un’offerta per {asset} {vin} del brand {brand}, modello {model} e versione {version}.',
            ogTitle: '{asset} {brand} {model} {version}',
            ogDescription: '{year}, {euroNorm}, {kilometers}: Partecipa all’asta B2B e fai un’offerta per questo {asset} usato direttamente online su Fleequid.com.',
        },
        auctions: {
            title: '{0} usati in vendita',
            description: 'Acquista online {0} usati su Fleequid.com : partecipa facilmente alle aste B2B dei i migliori {1} usati all’asta.',
        },
        cookiePolicy: {
            title: 'Cookie Policy',
            description: 'La gestione dei cookies relativa al portale Fleequid: leggi come gestiamo i cookies che utilizziamo per rendere la navigazione confortevole.',
        },
        homepage: {
            title: 'Vendita Autobus Usati',
            description: "Acquista o Vendi autobus e veicoli industriali all'asta: entra in Fleequid e partecipa facilmente alle aste B2B dei migliori veicoli industriali.",
        },
        privacyPolicy: {
            title: 'Privacy Policy',
            description: 'Informativa sulla privacy relativa al portale Fleequid: ti illustriamo chiaramente come gestiamo i dati di navigazione e tutti i tuoi dati.',
        },
        signup: {
            title: 'Aste Veicoli: Registrazione',
            description: 'Registrati ora a Fleequid per partecipare nel modo più facile e comodo alle aste di veicoli industriali, autobus e pullman nuovi e usati.',
            confirm: {
                title: 'Verifica email',
            },
        },
        termsAndConditions: {
            title: 'Termini e Condizioni',
            description: "Termini e condizioni di vendita e acquisto su Fleequid, il portale online di vendita all'asta e acquisto veicoli industriali e autobus.",
        },
        pricingSeller: {
            title: 'Commissioni Venditore',
            description: 'Commissioni d’asta applicate su Fleequid.com: dettagli sui prezzi e specifiche sul metodo di applicazione delle commissioni relative alle aste di vendita.',
        },
        pricingBuyer: {
            title: 'Commissioni Acquirente',
            description: 'Commissioni d’asta applicate su Fleequid.com: dettagli sui prezzi e specifiche sul metodo di applicazione delle commissioni relative alle aste di vendita.',
        },
    },
    signup: {
        link: 'Registrati',
        companyRegistration: 'Registra la tua azienda',
        subtitle: 'Inserisci i dati della tua azienda ed entra in un mondo nuovo di offerte.',
        companyInfo: 'Dati aziendali',
        companyDocuments: 'Documenti aziendali',
        contactPerson: 'Dati referente aziendale',
        accessCredentials: 'Credenziali di accesso',
        fleetHandling: 'Movimentazione flotta',
        addressAutofill: {
            title: 'Indirizzo aggiornato',
            description: "L'indirizzo aziendale è stato inserito a partire dalla partita IVA.",
        },
        fields: {
            address: 'Indirizzo',
            postCode: 'CAP',
            city: 'Città',
            province: 'Provincia',
            businessName: 'Ragione sociale',
            country: 'Nazione',
            pec: 'PEC',
            sdi: 'Codice SDI',
            vatId: {
                base: 'Partita IVA/VAT ID',
                invalid: 'La partita IVA non è valida secondo i dati VIES',
                validationUnavailable: 'Impossibile verificare la partita IVA per il paese inserito. Un rappresentante del servizio clienti verificherà i dati al termine della registrazione.',
            },
            companyRegistrationReportFile: 'Visura camerale',
            firstname: 'Nome',
            lastname: 'Cognome',
            phone: 'Numero di telefono',
            type: {
                label: 'Tipologia operatore',
                [UserType.Dealer]: 'Commerciante',
                [UserType.PeopleTransportOperator]: 'Operatore di trasporto passeggeri',
                [UserType.Scrapyard]: 'Demolitore',
                [UserType.Other]: 'Altro',
            },
            email: 'Email',
            password: 'Password',
            confirmPassword: 'Conferma password',
            stats: {
                sales: 'Quanti autobus pensi di vendere nel {year}?',
                purchase: 'Quanti autobus pensi di acquistare nel {year}?',
                unknown: 'Non lo so',
            },
            consent: 'Consenso',
            termsConditions: 'Ho preso visione e accetto i {0} del servizio.',
            vexatiousClauses: 'Ho preso visione e accetto le seguenti clausole:',
            privacy: "Ho preso visione e accetto l'{0}.",
        },
        hint: {
            phone: {
                text: 'Formato numero',
                rule: 'Inserisci il telefono nel formato +39 1234567890',
            },
        },
        validations: {
            requiredSDIOrPec: 'Inserisci almeno uno tra codice SDI o indirizzo PEC',
            companyRegistration: 'Il file selezionato è troppo grande. La dimensione massima consentita è di {size} MB',
        },
        success: {
            title: 'Grazie mille per aver completato la registrazione!',
            p1: 'I tuoi dati sono stati ricevuti con successo.',
            p2: 'A breve riceverai un’email di conferma, contenente un link su cui cliccare per verificare il tuo indirizzo email.',
            p3: 'Ti invitiamo a controllare anche nella cartella spam della tua casella di posta poiché, a causa delle preferenze da te configurate, l’email potrebbe essere stata contrassegnata come posta indesiderata.',
            p4: 'Ti invitiamo a visualizzare le aste online e scoprire se ci sono veicoli di tuo interesse. Vuoi vendere i tuoi autobus alla prossima asta? Contattaci a {0}.',
            p5: 'Grazie per averci scelto!',
        },
        confirm: {
            success: {
                title: 'La tua email è stata verificata!',
                p1: 'Grazie per aver confermato la tua email {email}.',
                p2: 'Ora il tuo account è attivo e puoi accedere a tutte le funzionalità del nostro servizio. ',
                p3: 'Se hai domande o necessiti di assistenza, contatta il nostro supporto a {0}. Benvenuto a bordo!',
            },
            error: {
                title: "Verifica dell'email non riuscita",
                p1: 'Siamo spiacenti, ma la verifica della tua email non è andata a buon fine.',
                p2: 'Potrebbe esserci stato un problema con il link di conferma. ',
                p3: 'Ti invitiamo a riprovare cliccando sul link di verifica che ti abbiamo inviato. Se il problema persiste, contatta il nostro supporto per assistenza a {0}.',
            },
            buyCta: 'Partecipa alle aste',
            hpCta: 'Torna alla homepage',
        },
    },
    termsConditions: {
        link: 'Termini e Condizioni',
    },
    login: {
        email: 'Email',
        password: 'Password',
        formSubtitle: 'Inserisci le tue credenziali Fleequid per partecipare alle aste',
        forgotPassword: 'Password dimenticata?',
        unregistered: 'Non hai un account Fleequid?',
        signupNow: 'Registrati subito',
    },
    passwordReset: {
        request: {
            title: 'Reimposta la password',
            subTitle: 'Inserisci la tua email e ti invieremo un messaggio per reimpostare la password.',
            submitButton: 'Invia email',
            onSuccessTitle: 'Richiesta accettata',
            onSuccessDescription: 'Riceverai un email con la procedura per completare la reimpostazione della password.',
        },
        reset: {
            title: 'Reimposta la password',
            description: 'Inserisci una nuova password. Una volta confermata la modifica tutte le sessioni attive saranno invalidate.',
            newPassword: 'Nuova password',
            confirmNewPassword: 'Conferma nuova password',
            submitButton: 'Modifica password',
            updateSuccess: 'Password modificata correttamente',
            tokenExpired: 'Il token per reimpostare la password è scaduto.',
            tokenInvalid: 'Il token per reimpostare la password è invalido.',
            startOverDescription: 'Esegui nuovamente la procedura per richiedere di reimpostare la password, contestualmente sarà generato un nuovo token.',
            startOverButton: 'Riprova',
        },
    },
    validation: {
        msg: {
            characters: 'Uno o più caratteri non validi',
            email: "L'email deve avere un formato user{'@'}domain.ext",
            incomplete: '{field} incompleto',
            invalid: '{field} invalido',
            invalidBid: "L'importo inserito non è valido",
            missing: '{field} mancante',
            max: 'Lunghezza massima {count} carattere | Lunghezza massima {count} caratteri',
            min: 'Lunghezza minima {count} carattere | Lunghezza minima {count} caratteri',
            length: 'La lunghezza deve essere esattamente di {count} carattere | La lunghezza deve essere esattamente di {count} caratteri',
            passwordWeak: 'Password troppo debole',
            passwordMatch: 'Le password non corrispondono',
            phone: 'Inserire solo numeri, spazi e il carattere +',
            recaptcha: 'Errore validazione reCAPTCHA',
            required: '{field} obbligatorio',
        },
        hint: {
            password: {
                text: 'Regole per una password efficace',
                rules: {
                    length: 'Almeno 8 caratteri',
                    uppercase: '1 carattere maiuscolo',
                    lowercase: '1 carattere minuscolo',
                    symbol: '1 carattere speciale',
                    number: '1 numero',
                },
            },
        },
    },
    profile: {
        title: 'Il tuo profilo',
        companyData: {
            readOnlyDisclaimer: 'I dati aziendali sono in sola lettura, per richiederne la modifica contattaci a {0}.',
        },
        userData: {
            saveChanges: 'Conferma nuovi dati',
            cancelChanges: 'Annulla modifiche',
            updateSuccess: 'Dati referente aziendale salvati correttamente',
        },
        changePassword: {
            title: 'Modifica password',
            description: 'La modifica della password invaliderà tutte le altre sessioni attive ad esclusione di quella corrente.',
            currentPassword: 'Password corrente',
            newPassword: 'Nuova password',
            confirmNewPassword: 'Conferma nuova password',
            submitButton: 'Conferma nuova password',
            updateSuccess: 'Password modificata correttamente',
        },
        changeEmail: {
            title: 'Modifica email',
            description: "Riceverai un'email con un collegamento per confermare la nuova email. La modifica della tua email invaliderà tutte le sessioni attive.",
            password: 'Password corrente',
            email: 'Email',
            submitButton: 'Conferma nuova email',
            updateSuccess: 'Email modificata correttamente',
        },
    },
    sessionExpired: {
        title: 'La tua sessione è scaduta',
        description: 'La sessione è stata invalidata perché le credenziali di accesso sono state cambiate. Accedi con le nuove credenziali.',
        close: 'Continua',
    },
    nuxtSiteConfig: {
        description: "Acquista o Vendi autobus e veicoli industriali all'asta: entra in Fleequid e partecipa facilmente alle aste B2B dei migliori veicoli industriali.",
    },
    negotiation: {
        underNegotiation: 'In trattativa',
        numberOfBids: '{count} offerta totale | {count} offerte totali',
        yourRequest: 'La tua richiesta',
        sellerRequest: 'Richiesta del venditore',
        yourOffer: 'La tua offerta',
        buyerOffer: 'Offerta dell’acquirente',
        youDeclineOfferNotice: 'Hai rifiutato l’offerta',
        sellerDeclineOfferNotice: 'Il venditore ha rifiutato l’offerta',
        expiredNotice: 'Il tempo della negoziazione é scaduto senza che si raggiungesse un accordo.',
        openNoticeForSeller: 'Non è stato raggiunto il prezzo di riserva iniziale del mezzo. Hai ancora {countdown} di tempo per negoziare con l’acquirente ({moreInfoLink}).',
        openNoticeForBuyer: 'Non è stato raggiunto il prezzo di riserva iniziale del mezzo. Hai ancora {countdown} di tempo per negoziare con il venditore ({moreInfoLink}).',
        underSellerReviewNoticeForSeller: 'L’acquirente ha selezionato quest’offerta come {finalOffer}. Hai ancora {countdown} di tempo per accettare o rifiutare ({moreInfoLink}).',
        underSellerReviewNoticeFinalOfferForSeller: 'importo massimo che intende offrire',
        underSellerReviewNoticeForBuyer: 'Hai selezionato quest’offerta come {finalOffer}. Il venditore ha ancora {countdown} di tempo per accettare o rifiutare ({moreInfoLink}).',
        underSellerReviewNoticeFinalOfferForBuyer: 'importo massimo che intendi offrire',
        moreInfoLink: 'più info',
        updateRequest: 'Modifica la tua richiesta',
        updateOffer: 'Modifica la tua offerta',
        acceptRequest: 'Accetta la richiesta',
        acceptOffer: 'Accetta l’offerta',
        buyerStopProposals: 'Blocca l’offerta corrente',
        sellerDecline: 'Termina negoziazione',
        cancel: 'Annulla',
        proposalModal: {
            title: 'Negozia il prezzo',
            subtitle: 'Hai {countdown} di tempo per negoziare l’offerta.',
            resetProposal: 'reimposta',
            stopProposalsLabel: 'Importo massimo che intendo offrire',
            stopProposalsDescription: 'Se selezioni quest’opzione, il venditore non potrà più negoziare, ma soltanto accettare o rifiutare quest’offerta.',
        },
        acceptModal: {
            titleForSeller: 'Accetti l’offerta?',
            titleForBuyer: 'Accetti la richiesta?',
        },
        sellerDeclineModal: {
            title: 'Termina la negoziazione',
            disclaimer: 'Procedendo in questo senso, concludi l’asta e perdi le offerte ricevute. Il nostro team ti ricontatterà al più presto per riprogrammare l’asta.',
            confirmButton: 'Termina la negoziazione',
        },
        buyerStopProposalsModal: {
            title: 'Blocca l’offerta corrente',
            disclaimer: 'Procedendo in questo senso, il venditore non potrà più negoziare, ma soltanto accettare o rifiutare quest’offerta.',
            confirmButton: 'Blocca l’offerta corrente',
        },
        backToNegotiation: 'Negozia ora',
        notifications: {
            proposalByBuyer: {
                title: 'Offerta modificata!',
                description: 'Hai modificato la tua offerta a {amount} per la negoziazione.',
            },
            proposalBySeller: {
                title: 'Richiesta modificata!',
                description: 'Hai modificato la tua richiesta a {amount} per la negoziazione.',
            },
            acceptByBuyer: {
                title: 'Richiesta accettata!',
                description: 'Hai accettato la richiesta del venditore di {amount}. Negoziazione conclusa con successo.',
            },
            acceptBySeller: {
                title: 'Offerta accettata!',
                description: 'Hai accettato l’offerta dell’acquirente di {amount}. Negoziazione conclusa con successo.',
            },
            declinedBySeller: {
                title: 'Negoziazione terminata!',
                description: 'Hai rifiutato in maniera definita l’offerta dell’acquirente di {amount}. Negoziazione conclusa senza successo.',
            },
            stopProposalsByBuyer: {
                title: 'Confermata ultima offerta!',
                description: 'La tua ultima offerta per la negoziazione è di {amount}. Attendi la decisione finale del venditore.',
            },
            apiError: {
                title: 'Si è verificato un errore imprevisto.',
                description: 'Non è stato possibile eseguire l’operazione richiesta. Aggiorna la pagina e prova a ripetere l’operazione.',
            },
        },
    },
};

export default it;
